<nav class="navbar navbar-trs navbar-expand-lg">
  <div class="container-fluid">
    <div class="logo-container">
      <a class="sidebar-logo" routerLink="/" (click)="clearSelectedFilters('/')">
        <img class="logo-img" [src]="logoUrlTab" alt="logo" (error)="handleImageError()" />
      </a>
    </div>
    <!-- Search Container -->
    <div class="nav-search-container" *ngIf="showSearch">
      <span class="search-icon-container">
        <svg
          *ngIf="urlData !== 'plans&Common'"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3496 14.3562C14.2563 14.4481 14.1306 14.4998 13.9996 14.5C13.8668 14.4994 13.7393 14.448 13.6434 14.3562L10.9434 11.65C9.80622 12.6051 8.34425 13.0844 6.86236 12.9878C5.38046 12.8912 3.99306 12.2263 2.98951 11.1316C1.98596 10.037 1.44375 8.59717 1.47597 7.11248C1.50818 5.62779 2.11233 4.21286 3.16241 3.16277C4.21249 2.11269 5.62743 1.50855 7.11212 1.47633C8.59681 1.44412 10.0366 1.98633 11.1313 2.98988C12.2259 3.99343 12.8908 5.38083 12.9874 6.86273C13.084 8.34462 12.6047 9.80659 11.6496 10.9437L14.3496 13.6437C14.3969 13.6903 14.4344 13.7457 14.46 13.8069C14.4856 13.868 14.4988 13.9337 14.4988 14C14.4988 14.0663 14.4856 14.1319 14.46 14.1931C14.4344 14.2542 14.3969 14.3097 14.3496 14.3562ZM7.24961 12C8.18907 12 9.10743 11.7214 9.88857 11.1995C10.6697 10.6775 11.2785 9.93567 11.638 9.06772C11.9976 8.19977 12.0916 7.24471 11.9083 6.3233C11.7251 5.40189 11.2727 4.55552 10.6084 3.89122C9.94407 3.22692 9.0977 2.77452 8.17629 2.59124C7.25488 2.40797 6.29981 2.50203 5.43186 2.86155C4.56391 3.22106 3.82206 3.82988 3.30013 4.61102C2.77819 5.39215 2.49961 6.31051 2.49961 7.24998C2.50126 8.50925 3.00224 9.71647 3.89268 10.6069C4.78312 11.4973 5.99034 11.9983 7.24961 12Z"
            fill="#000"
            fill-opacity="0.72"
          />
        </svg>

        <svg
          *ngIf="urlData === 'plans&Common'"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14.3496 14.3559C14.2563 14.4478 14.1306 14.4994 13.9996 14.4996C13.8668 14.499 13.7393 14.4476 13.6434 14.3559L10.9434 11.6496C9.80622 12.6047 8.34425 13.084 6.86236 12.9874C5.38046 12.8908 3.99306 12.2259 2.98951 11.1313C1.98596 10.0366 1.44375 8.59681 1.47597 7.11212C1.50818 5.62743 2.11233 4.21249 3.16241 3.16241C4.21249 2.11233 5.62743 1.50818 7.11212 1.47597C8.59681 1.44375 10.0366 1.98596 11.1313 2.98951C12.2259 3.99306 12.8908 5.38046 12.9874 6.86236C13.084 8.34426 12.6047 9.80623 11.6496 10.9434L14.3496 13.6434C14.3969 13.6899 14.4344 13.7453 14.46 13.8065C14.4856 13.8677 14.4988 13.9333 14.4988 13.9996C14.4988 14.0659 14.4856 14.1316 14.46 14.1927C14.4344 14.2539 14.3969 14.3093 14.3496 14.3559ZM7.24961 11.9996C8.18907 11.9996 9.10743 11.721 9.88857 11.1991C10.6697 10.6772 11.2785 9.9353 11.638 9.06736C11.9976 8.19941 12.0916 7.24434 11.9083 6.32293C11.7251 5.40152 11.2727 4.55515 10.6084 3.89085C9.94407 3.22655 9.0977 2.77416 8.17629 2.59088C7.25488 2.4076 6.29981 2.50166 5.43186 2.86118C4.56391 3.2207 3.82206 3.82952 3.30013 4.61065C2.77819 5.39178 2.49961 6.31015 2.49961 7.24961C2.50126 8.50888 3.00224 9.7161 3.89268 10.6065C4.78312 11.497 5.99034 11.998 7.24961 11.9996Z"
            fill="black"
            fill-opacity="0.3"
          />
        </svg>
      </span>
      <form [formGroup]="searchform" class="search-input">
        <input
          class="form-control search-input"
          type="text"
          formControlName="searchTerm"
          name="searchTerm"
          [placeholder]="placeHolder"
          [readonly]="urlData === 'plans&Common'"
          [ngClass]="{ 'readonly-input': urlData === 'plans&Common' }"
          autocomplete="off"
        />
      </form>
    </div>

    <!-- Filter, Theme toggle, login div -->
    <div class="d-flex ms-auto nav-right" id="navbarSupportedContent">
      <div class="nav-wallate-container">
        <a class="nav-anchor cursor-pointer" (click)="openWallet()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            class="nav-icon"
          >
            <path
              d="M15.1667 12.375C15.5639 12.375 15.8924 12.2451 16.1521 11.9854C16.4118 11.7257 16.5417 11.3972 16.5417 11C16.5417 10.6028 16.4118 10.2743 16.1521 10.0146C15.8924 9.75486 15.5639 9.625 15.1667 9.625C14.7694 9.625 14.441 9.75486 14.1813 10.0146C13.9215 10.2743 13.7917 10.6028 13.7917 11C13.7917 11.3972 13.9215 11.7257 14.1813 11.9854C14.441 12.2451 14.7694 12.375 15.1667 12.375ZM5.08333 19.25C4.57917 19.25 4.14742 19.0703 3.78808 18.711C3.42875 18.3517 3.24939 17.9202 3.25 17.4167V4.58333C3.25 4.07917 3.42967 3.64742 3.789 3.28808C4.14833 2.92875 4.57978 2.74939 5.08333 2.75H17.9167C18.4208 2.75 18.8526 2.92967 19.2119 3.289C19.5713 3.64833 19.7506 4.07978 19.75 4.58333V6.875H17.9167V4.58333H5.08333V17.4167H17.9167V15.125H19.75V17.4167C19.75 17.9208 19.5703 18.3526 19.211 18.7119C18.8517 19.0713 18.4202 19.2506 17.9167 19.25H5.08333ZM12.4167 15.5833C11.9125 15.5833 11.4808 15.4037 11.1214 15.0443C10.7621 14.685 10.5827 14.2536 10.5833 13.75V8.25C10.5833 7.74584 10.763 7.31409 11.1223 6.95475C11.4817 6.59542 11.9131 6.41606 12.4167 6.41667H18.8333C19.3375 6.41667 19.7693 6.59633 20.1286 6.95567C20.4879 7.315 20.6673 7.74645 20.6667 8.25V13.75C20.6667 14.2542 20.487 14.6859 20.1277 15.0453C19.7683 15.4046 19.3369 15.5839 18.8333 15.5833H12.4167ZM18.8333 13.75V8.25H12.4167V13.75H18.8333Z"
              fill="#6365EF"
            />
          </svg>
          <span class="nav-text">$ {{ userDetails?.walletBalance?.toFixed(2) ?? 0.0 }}</span>
        </a>
      </div>

      <div class="d-flex align-items-center">
        <div class="user-name-container">Hi, {{ userDetails?.firstName }}</div>
        <div class="dropdown nav-user-dropdown">
          <a
            class="btn-dropdown"
            href="#"
            role="button"
            id="customerDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="user-profile-div">
              <img
                class="profile-image"
                [src]="userDetails?.profileImageURL || '/assets/images/navbar/user.png'"
                alt="User-Profile"
              />
            </div>
            <div class="menu-bars-container">
              <img class="menu-bars-icon" src="/assets/images/navbar/menu-bars.svg" alt="Menu" />
            </div>
          </a>

          <ul class="dropdown-menu" aria-labelledby="customerDropdown">
            <li>
              <a
                class="dropdown-item head d-flex"
                (click)="myQr()"
                [ngClass]="{ disabled: routeUrl === '/qr' }"
              >
                <span class="menu-title qr-code"> My QR Code </span>
              </a>
            </li>
            <li class="d-sm-none">
              <a class="dropdown-item head d-flex" href="/settings">
                <span class="menu-title"> Settings </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item head d-flex" (click)="openWalkthroughVideo()">
                <span class="qr-code w-100 d-flex justify-content-between">
                  <span class="menu-title"> Walkthrough Video </span>
                  <span class="svg-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.65967 12.3536C5.44678 12.1583 5.44678 11.8417 5.65967 11.6464L9.25 8.35355C9.4629 8.15829 9.4629 7.84171 9.25 7.64645L5.65968 4.35355C5.44678 4.15829 5.44678 3.84171 5.65968 3.64645C5.87257 3.45118 6.21775 3.45118 6.43065 3.64645L10.021 6.93934C10.6597 7.52513 10.6597 8.47487 10.021 9.06066L6.43065 12.3536C6.21775 12.5488 5.87257 12.5488 5.65967 12.3536Z"
                        fill="#1C1C1C"
                      />
                    </svg>
                  </span>
                </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item head d-flex" (click)="signOut()">
                <span class="qr-code w-100 d-flex justify-content-between">
                  <span class="menu-title"> Log Out </span>
                  <span class="svg-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.65967 12.3536C5.44678 12.1583 5.44678 11.8417 5.65967 11.6464L9.25 8.35355C9.4629 8.15829 9.4629 7.84171 9.25 7.64645L5.65968 4.35355C5.44678 4.15829 5.44678 3.84171 5.65968 3.64645C5.87257 3.45118 6.21775 3.45118 6.43065 3.64645L10.021 6.93934C10.6597 7.52513 10.6597 8.47487 10.021 9.06066L6.43065 12.3536C6.21775 12.5488 5.87257 12.5488 5.65967 12.3536Z"
                        fill="#1C1C1C"
                      />
                    </svg>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
