import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ColorsService } from 'api/colors.service';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs';
import { WalkthroughVideoComponent } from 'src/app/features/dashboard/component/walkthrough-video/walkthrough-video.component';
import { OrderService } from 'src/app/features/dashboard/service/order.service';
import { ConfigData, User } from '../../models';
import { LocalStorageService, NavigationService } from '../../service';
import { ConfigService } from '../../service/config.service';
import { DashboardService } from '../../service/dashboard.service';
import { LogoService } from '../../service/logo.service';
import { SearchService } from '../../service/search/search.service';
import { UserService } from '../../service/user.service';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements AfterViewInit, OnInit {
  userDetails!: User;
  showSearch = true;
  routeUrl!: string;
  logoUrlTab: any;

  @ViewChild('searchForm', { static: false }) searchForm!: NgModel;
  initValue = '';
  searchform!: FormGroup;
  urlList: string[] = [
    'traveller',
    'plans',
    'plans&Common',
    'order-summary',
    '/plans',
    '/subscribers',
    '/purchase-history',
    '/support',
  ];

  routerUrls: string[] = ['/', '/qr', '/reports'];

  urlData!: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _FILTERS: any;
  placeHolder = '';

  constructor(
    public router: Router,
    private _localStorage: LocalStorageService,
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private _searchService: SearchService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private logoService: LogoService,
    private dashboardService: DashboardService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private dialogService: DialogService
  ) {
    // Subscribe to filters
    this._searchService.getFilters().subscribe((filters) => {
      this._FILTERS = filters;
    });

    this.configService.getLocalConfig().subscribe((configData: ConfigData) => {
      if (configData != undefined && configData != null) {
        this.checkAndAssignLogoUrl(configData);
      }
    });

    // Subscribe to stepper
    this._searchService.getStepper().subscribe((results) => {
      this.urlData = results;
      if (this.urlList.includes(this.urlData)) {
        this.showSearch = true;
        this.placeHolder = this.determinePlaceholder(this.urlData);
        this.searchform?.reset();
      } else {
        this.showSearch = false;
      }
    });

    // Subscribe to router events
    router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.routeUrl = route.url;
        if (this.routerUrls.includes(route.url)) {
          this.showSearch = false;
        } else {
          this.showSearch = true;
          this.placeHolder = this.determinePlaceholder(this.routeUrl);
        }
        this.searchform?.reset();
      }
    });

    // Subscribe to current user
    userService.getCurrentUser().subscribe((result) => {
      this.userDetails = result;
    });
  }

  /**
   * Lifecycle hook called after component initialization.
   */
  ngOnInit(): void {
    if (!this._localStorage.getToken()) {
      this.configService.clearLocalStorage();
      this.navigationService.navigateToSignInWithQueryParams();
    }
    this.formInit();
  }

  /**
   * Initialize form.
   */
  formInit(): void {
    this.searchform = this.fb.group({
      searchTerm: [''],
    });
  }

  /**
   * Lifecycle hook called after the view has been initialized.
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.searchform.controls['searchTerm'].valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((data) => data !== null),
          switchMap((data) => {
            if (typeof this._FILTERS === 'object') {
              this._FILTERS.currentPage = this._FILTERS?.currentPage ? 1 : undefined;
            }
            return this._searchService.globalSearch(
              this.urlData?.includes('/') ? undefined : this.urlData,
              data,
              this.routeUrl,
              undefined,
              undefined,
              this._FILTERS
            );
          })
        )
        .subscribe((res) => {
          this.cdr.detectChanges();
          this._searchService.setResults(res);
        });
    }, 300);
  }

  /**
   * Sign out user.
   */
  signOut() {
    this._localStorage.clear();
    this.logoService.updateFavicon(undefined, 'assets/images/common/favicon.ico');
    ColorsService.resetCssVariables();
    this.configService.configDetails$.next(undefined);
    this.router.navigate(['/auth/signin']);
  }

  /**
   * Navigate to QR page.
   */
  myQr() {
    this.router.navigate(['/qr']);
  }

  /**
   * Determine placeholder text based on URL endpoint.
   *
   * @param urlEndPoint URL endpoint
   * @returns Placeholder text
   */
  private determinePlaceholder(urlEndPoint: string): string {
    const splittedUrl = urlEndPoint.split('/');
    const first = splittedUrl[1];
    const paramId = splittedUrl[2];

    if (first) {
      if (paramId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const endpointMappings: any = {
          'purchase-history': 'Search by Customer Name or Email ID',
          subscribers: 'Search by Plan Name',
          support: '',
        };

        return endpointMappings[first] || 'Search';
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mappings: any = {
          'purchase-history': 'Search by Order No.',
          subscribers: 'Search by Subscriber Name or Email ID',
          support: 'Search by Customer Name or Order No.',
          plans: 'Search by Plan Name or Country',
          wallet: 'Search by Transaction History',
        };

        const result = mappings[first] || 'Search';
        return result;
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mappings: any = {
        traveller: 'Search by Traveller Name or Email ID',
        plans: 'Search by Traveller Name',
        'plans&Common': 'Search',
        'order-summary': 'Search by Customer Name or Email ID',
      };
      const result = mappings[urlEndPoint] || 'Search';
      return result;
    }
  }

  /**
   * Navigate to wallet page.
   */
  openWallet() {
    this.router.navigate(['/wallet']);
  }

  async checkAndAssignLogoUrl(configData: any) {
    this.logoUrlTab = await this.logoService.checkAndAssignLogoUrl(
      configData?.trsConfig?.logoSquare
    );
  }

  clearSelectedFilters(link: string) {
    this._searchService.selectedFilters = null;
    this.orderService._CURRENT_BACK_STEP = undefined;
    this.orderService.resetOrder();
    this._searchService.setStepper(link);
    this.dashboardService.purchaseSource.next(undefined);
    this._localStorage.removeItem('orderObj');
    if (link == '/') {
      this.dashboardService.isShowDashboard.next(true);
    }
  }

  /**
   * Handles image loading errors for the main logo or the logo tab.
   * If an error occurs, sets the respective logo URL to a fallback image URL.
   * @param type - The type of logo ('logo' for the main logo, 'logoTab' for the logo tab)
   */
  handleImageError() {
    this.logoUrlTab = '/assets/images/auth/logo-tab.svg';
  }

  /**
   * Opens a modal dialog to play the walkthrough video.
   *
   */
  openWalkthroughVideo() {
    this.dialogService
      .openModal(WalkthroughVideoComponent, {
        cssClass: 'modal-fullscreen my-auto',
        // context: { data: plan },
      })
      .subscribe((err) => {
        console.log('err :', err);
      });
  }
}
