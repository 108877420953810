/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { OrderService } from 'src/app/features/dashboard/service/order.service';
import { ConfigData } from '../../models';
import { ConfigService } from '../config.service';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  serverUrl: string | undefined;
  private _searchResults$ = new BehaviorSubject<any>(undefined);
  private _searchUrl$ = new BehaviorSubject<any>(undefined);
  private _stepperName$ = new BehaviorSubject<any>(undefined);
  _filterObj$ = new BehaviorSubject<any>(undefined);
  public searchedTerm: any = undefined;
  public selectedFilters: any = undefined;
  currentOrder: any;
  travellerList: any[] = [];
  showSearch = true;
  currentRouteData: any;

  constructor(
    private orderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private configService: ConfigService
  ) {
    // Set server URL from local config or environment
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.serverUrl = configData?.trsConfig?.serverUrl;
    });
  }

  /**
   * Retrieves the stepper name.
   *
   * @returns An Observable of the stepper name.
   */
  getStepper() {
    return this._stepperName$.asObservable();
  }

  /**
   * Sets the stepper name.
   *
   * @param results - The stepper name to set.
   */
  setStepper(results: any) {
    this._stepperName$.next(results);
  }

  /**
   * Retrieves the filter object.
   *
   * @returns An Observable of the filter object.
   */
  getFilters() {
    return this._filterObj$.asObservable();
  }

  /**
   * Sets the filter object.
   *
   * @param results - The filter object to set.
   */
  setFilters(results: any) {
    this._filterObj$.next(results);
  }

  /**
   * Retrieves the search results.
   *
   * @returns An Observable of the search results.
   */
  getResults() {
    return this._searchResults$.asObservable();
  }

  /**
   * Sets the search results.
   *
   * @param results - The search results to set.
   */
  setResults(results: any) {
    this._searchResults$.next(results);
  }

  /**
   * Retrieves the search URL.
   *
   * @returns An Observable of the search URL.
   */
  getUrl() {
    return this._searchUrl$.asObservable();
  }

  /**
   * Sets the search URL.
   *
   * @param results - The search URL to set.
   */
  setUrl(results: any) {
    this._searchUrl$.next(results);
  }

  /**
   * Retrieves search results based on the type and search term.
   *
   * @param type - The type of search.
   * @param searchTerm - The search term.
   * @returns An Observable of the search results.
   */
  getSearchResult(type: any, searchTerm: string): Observable<any> {
    this.searchedTerm = searchTerm;

    this.currentOrder = this.orderService.getCurrentOrder();
    this.travellerList = this.currentOrder?.travellers;

    if (!searchTerm) return of(this.travellerList);
    if (type == 'plans') {
      const matchingElements = this.travellerList.filter((item) => {
        const searchValueName = item.name || ''; // Set to an empty string if 'name' is undefined
        // Check if either 'name' contains the search term
        return searchValueName.toLowerCase().includes(searchTerm.toLowerCase());
      });

      return of(matchingElements);
    } else if (type == 'traveller') {
      const matchingElements = this.travellerList.filter((item) => {
        const searchValueName = item?.traveller?.name || ''; // Set to an empty string if 'name' is undefined
        const searchValueEmail = item?.traveller?.email || ''; // Set to an empty string if 'email' is undefined
        // Check if either 'name' or 'email' contains the search term
        return (
          searchValueName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          searchValueEmail.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

      return of(matchingElements);
    } else {
      const matchingElements = this.travellerList.filter((item) => {
        const searchValueName = item.name || ''; // Set to an empty string if 'name' is undefined
        const searchValueEmail = item.email || ''; // Set to an empty string if 'email' is undefined
        // Check if either 'name' or 'email' contains the search term
        return (
          searchValueName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          searchValueEmail.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

      return of(matchingElements);
    }
  }

  /**
   * Performs a global search.
   *
   * @param type - The type of search.
   * @param searchTerm - The search term.
   * @param url - The URL for the search.
   * @param currentPage - The current page number.
   * @param itemsPerPage - The number of items per page.
   * @param filterObj - The filter object.
   * @returns An Observable of the search results.
   */
  globalSearch(
    type: any,
    searchTerm: string,
    url: string,
    currentPage: number | undefined,
    itemsPerPage: number | undefined,
    filterObj?: any
  ): Observable<any> {
    if (type) {
      return this.getSearchResult(type, searchTerm);
    } else {
      return this.getComponentBasedSearchResult(
        url,
        searchTerm,
        currentPage,
        itemsPerPage,
        filterObj
      );
    }
  }

  /**
   * Retrieves search results based on the URL and search term.
   *
   * @param url - The URL for the search.
   * @param searchTerm - The search term.
   * @param currentPage - The current page number.
   * @param itemsPerPage - The number of items per page.
   * @param filterObj - The filter object.
   * @returns An Observable of the search results.
   */
  getComponentBasedSearchResult(
    url: string,
    searchTerm: string,
    currentPage: number | undefined,
    itemsPerPage: number | undefined,
    filterObj: any
  ): Observable<any> {
    const {
      selectedCountry,
      fromDate,
      toDate,
      dateRange,
      subscriberId,
      orderId,
      status,
      purchaseType,
    } = filterObj || {};
    this.searchedTerm = searchTerm != null && searchTerm != '' ? searchTerm : undefined;
    let params: any = {
      search: this.searchedTerm,
      itemsPerPage,
      currentPage,
      selectedCountry,
      status,
      fromDate,
      toDate,
      dateRange,
      subscriberId,
      orderId,
      purchaseType,
    };
    // Conditionally add itemsPerPage and currentPage if they are defined
    if (itemsPerPage && currentPage) {
      params.itemsPerPage = itemsPerPage;
      params.currentPage = currentPage - 1;
    }

    if (params.search && params.search?.length > 2) {
      params = JSON.parse(JSON.stringify(params));
    } else {
      params.search = undefined;
      params = JSON.parse(JSON.stringify(params));
    }

    const endpoint: any = `${this.serverUrl}/${this.determineEndpoint(url)}`;
    return this.http.get(endpoint, { params });
  }

  /**
   * Determines the endpoint based on the URL.
   *
   * @param urlEndPoint - The URL endpoint.
   * @returns The endpoint string.
   */
  private determineEndpoint(urlEndPoint: any): string {
    const splittedUrl = urlEndPoint.split('/');
    const first = splittedUrl[1];
    const paramId = splittedUrl[2];

    if (first) {
      if (paramId) {
        const endpointMappings: any = {
          'purchase-history': `subscriptions/${paramId}`,
          subscribers: 'SubscriptionsBySubscriberId',
          support: 'supportById',
          plans: 'plansByplanId',
          report: 'reportById',
        };

        return endpointMappings[first] || 'undefined';
      } else {
        const mappings: any = {
          'purchase-history': 'purchaseHistory',
          subscribers: 'subscribers',
          support: 'support-requests',
          plans: 'allPlans',
          report: 'report',
          wallet: 'eWallet/transactions',
        };

        const result = mappings[first] || 'other';
        return result;
      }
    }

    return 'undefined';
  }
}
