import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
  StateKey,
  makeStateKey,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorsService } from 'api/colors.service';
import { BehaviorSubject, Observable, map, shareReplay, take, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigData } from '../models';
import { AlertService } from './alert.service';
import { BrowserService } from './browser.service';
import { EncryptionDecryptionService } from './encryption-decryption.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private renderer: Renderer2;
  configDetails$ = new BehaviorSubject<any>(undefined);
  private inProgressSubject = new BehaviorSubject<boolean>(false);
  inProgress$ = this.inProgressSubject.asObservable();
  initialData: any;
  CONFIG_DATA_KEY: StateKey<any> = makeStateKey<any>('CONFIG_DATA');
  lookupServerUrl = environment.lookupServerUrl;
  private configDetailsSubject = new BehaviorSubject<ConfigData | null>(null);
  serverUrl: string | undefined;
  private isBrowser$: Observable<boolean> = this.browserService.getIsBrowser().pipe(shareReplay(1));

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private browserService: BrowserService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.isBrowser$.pipe(take(1)).subscribe((isBrowser) => {
      if (isBrowser) {
        this.saveInitialDataToLocalStorage();

        // Subscribe to local configuration changes to update the server URL if needed.
        this.getLocalConfig().subscribe((configData: any) => {
          this.serverUrl = configData?.trsConfig?.serverUrl;
        });
      }
    });
  }

  /**
   * This function saves the initial data from SSR to local storage.
   * If the initial data is present on the global window object, it decrypts, processes, and stores it in local storage.
   * If the initial data is not present, it attempts to retrieve and decrypt the data from local storage.
   */
  saveInitialDataToLocalStorage(): void {
    if (typeof window !== 'undefined' && window.__INITIAL_DATA__) {
      this.handleInitialDataFromWindow();
    } else {
      this.handleDataFromLocalStorage();
    }
  }

  /**
   * Handle initial data from the global window object.
   * Decrypts the data, stores it in local storage, and updates the observable.
   */
  private handleInitialDataFromWindow(): void {
    // Retrieve and decrypt the initial configuration data from the global window object
    const configData = window.__INITIAL_DATA__;

    const decryptedData = this.encryptionDecryptionService.decryptData(configData);

    if (!decryptedData?.trsConfig?.serverUrl) {
      // Call the helper function to handle invalid configurations
      this.handleInvalidConfigError(decryptedData, true);
    } else {
      const encryptedData = EncryptionDecryptionService.encryptData(decryptedData);

      // Store the encrypted configuration data in local storage
      this.localStorageService.setItem('config', JSON.stringify(encryptedData));

      // Store the region data in local storage
      this.localStorageService.setItem('region', JSON.stringify(decryptedData?.region));

      // Update the observable with the decrypted configuration data
      this.configDetails$.next(decryptedData);

      // Optionally, remove the initial data from the global window object
      delete window.__INITIAL_DATA__;
    }
  }

  /**
   * Handle configuration data from local storage.
   * Attempts to retrieve, parse, and decrypt the data, updating the observable or handling unexpected formats.
   */
  private handleDataFromLocalStorage(): void {
    const storedConfigData = this.localStorageService.getItem('config');

    if (storedConfigData) {
      const configData = this.parseConfigData(storedConfigData);

      if (configData.isEncrypted) {
        this.decryptAndProcessData(configData.data);
      } else {
        this.handleUnexpectedFormatData(configData.data);
      }
    }
  }

  /**
   * Parses the configuration data from local storage.
   * Attempts to determine if the data is JSON or encrypted.
   * @param storedConfigData - The data retrieved from local storage.
   * @returns An object indicating whether the data is encrypted and the parsed data.
   */
  private parseConfigData(storedConfigData: string): { isEncrypted: boolean; data: any } {
    let configData: any;
    let isEncrypted = false;

    try {
      // Attempt to parse the stored data as JSON
      configData = JSON.parse(storedConfigData);

      // Check if the parsed data is an object and not an array
      if (configData && typeof configData === 'object' && !Array.isArray(configData)) {
        // Valid JSON object
      } else {
        // Invalid JSON object format
        isEncrypted = true;
      }
    } catch (error) {
      // If parsing fails, assume it's encrypted data
      isEncrypted = true;
      configData = storedConfigData;
    }

    return { isEncrypted, data: configData };
  }

  /**
   * Decrypt and process the encrypted configuration data.
   * Updates the observable with the decrypted data or handles decryption failure.
   * @param encryptedData - The encrypted configuration data
   */
  private decryptAndProcessData(encryptedData: string): void {
    try {
      const decryptedData = this.encryptionDecryptionService.decryptData(encryptedData);
      this.generateCssVariables(decryptedData?.trsConfig?.colors);
      this.configDetails$.next(decryptedData);
    } catch (error) {
      console.error('Failed to decrypt config data:', error);
      // Handle decryption failure
      this.navigateToSignIn();
    }
  }

  /**
   * Handle unexpected format data.
   * Clears local storage and optionally redirects to a sign-in page.
   * @param data - The data in an unexpected format
   */
  private handleUnexpectedFormatData(data: any): void {
    // Handle unexpected format data appropriately
    this.navigateToSignIn();
  }

  /**
   * Fetches configuration data from the server based on mobile number and country code
   * and updates local storage.
   *
   * @param mobile - The mobile number to fetch the config data for.
   * @param countryCode - The country code to fetch the config data for.
   * @returns Observable of ConfigData.
   */
  getConfig(mobile: string, countryCode: string): Observable<ConfigData> {
    this.showLoader();
    const encodedCountryCode = encodeURIComponent(countryCode);
    const url = `${this.lookupServerUrl}/trsapp/mobile?mobile=${mobile}&countryCode=${encodedCountryCode}`;
    return this.fetchAndProcessConfigData(url, true, mobile, countryCode);
  }

  /**
   * Fetches configuration data from the server based on customer ID
   * and updates local storage.
   *
   * @param customerId - The customer ID to fetch the config data for.
   * @returns Observable of ConfigData.
   */
  getConfigByCustomerId(customerId: string): Observable<ConfigData> {
    this.showLoader();
    const url = `${this.serverUrl}/users/configuration?customerId=${customerId}`;
    return this.fetchAndProcessConfigData(url, false);
  }

  /**
   * Fetches configuration data from the provided URL and processes it.
   * This includes encrypting the data, storing it in local storage,
   * updating subjects, and generating CSS variables.
   *
   * @param url - The URL to fetch the config data from.
   * @param setRegion - Boolean flag indicating whether to set the region in local storage.
   * @returns Observable of ConfigData.
   */
  fetchAndProcessConfigData(
    url: string,
    setRegion: boolean,
    mobile?: string,
    countryCode?: string
  ): Observable<ConfigData> {
    if (!isPlatformBrowser(this.platformId)) {
      console.error('Not running in a browser environment.');
      return throwError(() => new Error('Not running in a browser environment.'));
    }

    return this.http.get<any>(url).pipe(
      map((result: any) => {
        if (!result) {
          return throwError(() => new Error('No config data available'));
        }

        // Call the helper function to handle invalid configurations
        this.handleInvalidConfigError(result, false);

        this.processConfigData(result, setRegion, mobile, countryCode);
        return result;
      })
    );
  }

  /**
   * Processes the configuration data.
   *
   * This function performs the following steps:
   * 1. Encrypts the data and stores it in local storage.
   * 2. Optionally sets the region in local storage if the setRegion flag is true.
   * 3. Updates the config details observable and subject.
   * 4. Generates CSS variables from the color configuration in the data.
   *
   * @param data - The configuration data to be processed.
   * @param setRegion - A boolean flag indicating whether to set the region in local storage.
   */
  processConfigData(data: any, setRegion?: boolean, mobile?: string, countryCode?: string): void {
    // Add mobile and countryCode if they are provided
    if (mobile) {
      data.mobile = mobile;
    }
    if (countryCode) {
      data.countryCode = countryCode;
    }

    const encryptedData = EncryptionDecryptionService.encryptData(data);
    this.localStorageService.setItem('config', JSON.stringify(encryptedData));
    if (setRegion) {
      this.localStorageService.setItem('region', JSON.stringify(data?.region));
    }
    this.configDetails$.next(data);
    this.configDetailsSubject.next(data);

    const colors = data?.trsConfig?.colors;
    this.generateCssVariables(colors);
  }

  /**
   * Retrieves the local configuration data as an observable.
   *
   * @returns An observable of the local configuration data.
   */
  getLocalConfig() {
    return this.configDetails$.asObservable();
  }

  /**
   * Shows the loader.
   */
  showLoader() {
    this.inProgressSubject.next(true);
  }

  /**
   * Hides the loader.
   */
  hideLoader() {
    this.inProgressSubject.next(false);
  }

  /**
   * Clears all items from local storage and resets the configuration details observable.
   */
  clearLocalStorage() {
    this.localStorageService.clear();
    this.configDetails$.next(undefined);
  }

  /**
   * Clears local storage, resets the configuration details observable, and navigates to the sign-in page.
   */
  navigateToSignIn() {
    this.clearLocalStorage();
    this.router.navigate(['auth/signin']);
  }

  /**
   * Generates CSS variables from the given colors object.
   *
   * @param colors - The colors object to generate CSS variables from.
   */
  generateCssVariables(colors: any) {
    ColorsService.generateCssVariables(colors, 'update');
  }

  /**
   * Validates the provided configuration object and handles errors based on the provided flag.
   *
   * @param configData - The configuration object containing the `trsConfig` property.
   *                     If the `serverUrl` is missing or invalid, an error will either be thrown
   *                     or displayed in an alert popup depending on the `showAlert` flag.
   * @param showAlert - A boolean flag indicating whether to display an error alert using the alert service.
   *                    If true, an error popup is shown and the function returns immediately;
   *                    if false, the error is thrown.
   * @throws {Error} - Throws an error if the `serverUrl` is invalid and `showAlert` is false.
   * @returns {void | never} - Stops further execution if an error is detected and `showAlert` is true.
   */
  handleInvalidConfigError(configData: any, showAlert: boolean): void {
    if (!configData?.trsConfig?.serverUrl) {
      ColorsService.resetCssVariables();
      const customError = new Error('Invalid Customer Configuration. Please contact support.');
      (customError as any).status = 400;
      (customError as any).error = { message: customError.message };

      if (!showAlert) {
        throw customError; // Throw error when showAlert is false
      } else {
        this.alertService.error(customError.message, (customError as any).status);
        return; // Return to prevent further execution
      }
    }
  }
}
