export class ColorsService {
  static generateCssVariables(
    colors: { [key: string]: string },
    operationKey: 'generate' | 'update'
  ): string | void {
    // Map the API response keys to the CSS variable names
    const variableMappings: { [key: string]: string } = {
      primary: '--color-primary',
      secondary: '--color-secondary',
      primaryFont: '--color-primary-font',
      secondaryFont: '--color-secondary-font',
      button: '--color-primary-button',
      secondaryButton: '--color-secondary-button-text',
      background: '--color-background',
      navigationBar: '--color-navbar',
    };

    if (operationKey === 'generate') {
      // Generate the CSS variable styles
      return Object.keys(variableMappings)
        .map((key) => {
          const variableName = variableMappings[key];
          const value = colors[key];
          if (value) {
            return `${variableName}: ${value};`;
          }
          return ''; // Ensure all paths return a value
        })
        .filter(Boolean)
        .join(' ');
    } else if (operationKey === 'update') {
      // Update each color variable
      const root = document.documentElement; // Assumes this is being called in a browser environment
      Object.keys(variableMappings).forEach((key) => {
        const variableName = variableMappings[key];
        const value = colors[key];
        if (value) {
          root.style.setProperty(variableName, value);
        }
      });
    }
  }

  static resetCssVariables(): void {
    // Default values should match those in your styles.scss
    const defaultColors = {
      primary: '#6365EF',
      secondary: '#F0F0FE',
      primaryFont: '#303030',
      secondaryFont: '#686868',
      button: '#6365EF',
      secondaryButton: '#6365EF',
      background: '#F0F0FE',
      navigationBar: '#FFFFFF',
    };

    // Call the update method to set default values
    this.generateCssVariables(defaultColors, 'update');
  }
}
