<div class="lock-screen-decide-popup">
  <div class="d-flex justify-content-between modal-commons-head">
    <div class="plan-header">Purchase Mode</div>
    <div>
      <svg
        class="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        (click)="close()"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="black"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <div class="modal-common-body">
    <p class="modal-subtext">Please choose the purchase Mode you want to continue with</p>

    <div class="modal-common-body-inner">
      <div class="radio-buttons-modal-perent">
        <div class="radio-buttons">
          <label class="cursor-pointer">
            <input
              class="custom-radio"
              type="radio"
              name="purchaseMethod"
              [value]="true"
              [(ngModel)]="isLockScreenMode"
            />
            Customer Purchase Mode
          </label>
        </div>

        <div class="radio-buttons">
          <label class="cursor-pointer">
            <input
              type="radio"
              name="purchaseMethod"
              [value]="false"
              [(ngModel)]="isLockScreenMode"
            />
            Agent Purchase Mode
          </label>
        </div>
      </div>

      <div>
        <button class="btn btn-primary w-100" (click)="continue()">Continue</button>
      </div>
    </div>
  </div>
</div>
