import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class EncryptionDecryptionService {
  static encryptData(data: any): string {
    const key = environment.secretKey;
    const jsonString = JSON.stringify(data);
    return CryptoJS.AES.encrypt(jsonString, key).toString();
  }

  decryptData(encryptedData: string): any {
    const key = environment.secretKey;
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    const jsonString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(jsonString);
  }
}
