import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(
    value: Array<any> | undefined,
    searchTerm = '',
    keyIndices: Array<string> = []
  ): Array<any> | null {
    if (!value) return null;
    if (!searchTerm) return value;

    const filteredValue = value?.filter((item: any) => {
      return keyIndices.some((key) => {
        const searchValue = item[key];
        return searchValue?.toString().toLowerCase().includes(searchTerm.toLowerCase());
      });
    });

    if (keyIndices.includes('dial_code')) {
      filteredValue.sort((a, b) => {
        const codeA = a['dial_code'].replace('+', '');
        const codeB = b['dial_code'].replace('+', '');
        return parseInt(codeA) - parseInt(codeB);
      });
    }

    return filteredValue;
  }
}
