<div class="lock-screen-decide-popup modal-commons">
  <div class="d-flex justify-content-between modal-commons-head">
    <div class="plan-header">Scan QR Code for Payment</div>
    <div>
      <svg
        class="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        (click)="close()"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="black"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <div class="modal-common-body">
    <div class="modal-common-body-inner">
      <div class="qr-section">
        <div class="header d-flex justify-content-between">
          <p class="title">Payable Amount</p>
          <p class="value">$ {{ data?.totalSellingPrice }}</p>
        </div>

        <hr />

        <div class="qr d-flex justify-content-center">
          <span>
            <qrcode
              #parent
              [qrdata]="data?.paymentLink ?? ''"
              [allowEmptyString]="true"
              [cssClass]="'center'"
              [errorCorrectionLevel]="'H'"
              [elementType]="'canvas'"
              [scale]="1"
              title="Scan QR code"
              [width]="200"
              [margin]="4"
              (qrCodeURL)="onChangeURL($event)"
            ></qrcode>
          </span>
        </div>
      </div>

      <div>
        <button class="btn btn-primary w-100" (click)="continue()">Continue</button>
      </div>
    </div>
  </div>
</div>
