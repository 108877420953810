import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   * Navigates to the home route ('/') with preserved query parameters.
   * If 'walkthrough' query parameter exists in current route, it is preserved.
   */
  navigateToHomeWithQueryParams() {
    const queryParams = this.getQueryParamsWithWalkthrough();
    this.router.navigate(['/'], { queryParams });
  }

  /**
   * Navigates to the signin route ('/auth/signin') with preserved query parameters.
   * If 'walkthrough' query parameter exists in current route, it is preserved.
   */
  navigateToSignInWithQueryParams() {
    const queryParams = this.getQueryParamsWithWalkthrough();
    this.router.navigate(['/auth/signin'], { queryParams });
  }

  /**
   * Retrieves query parameters from the current route, preserving 'walkthrough' if present.
   * @returns Object containing query parameters with 'walkthrough' if found.
   */
  private getQueryParamsWithWalkthrough(): any {
    const walkthrough = this.route.snapshot.queryParams['walkthrough'];
    const queryParams: any = {};
    if (walkthrough) {
      queryParams.walkthrough = walkthrough;
    }
    return queryParams;
  }
}
