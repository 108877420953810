import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigData } from './global/models';
import { BrowserService } from './global/service';
import { ConfigService } from './global/service/config.service';
import { LogoService } from './global/service/logo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Glowing Bud Travel';
  isBrowser = false;
  private renderer!: Renderer2;
  configData!: ConfigData;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platformId: any,
    private browserService: BrowserService,
    private configService: ConfigService,
    private titleService: Title,
    private logoService: LogoService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    browserService.setIsBrowser(this.isBrowser);
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.titleService.setTitle(configData?.trsConfig?.name ?? 'Glowingbud');
      this.logoService.updateFavicon(
        configData?.trsConfig?.favicon,
        'assets/images/common/favicon.ico'
      );
    });
  }
}
