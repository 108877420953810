import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigData } from 'src/app/global/models';
import { BrowserService } from 'src/app/global/service';
import { ConfigService } from 'src/app/global/service/config.service';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  serverUrl: string | undefined;
  isBrowser = false;

  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private configService: ConfigService
  ) {
    // Subscribe to browser detection service to determine if the code is running in a browser
    browserService.getIsBrowser().subscribe((isBrowser: boolean) => (this.isBrowser = isBrowser));

    // Fetch local configuration data
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      // Set the server URL based on the configuration data
      this.serverUrl = configData?.trsConfig?.serverUrl;
    });
  }

  /**
   * Retrieve transaction history based on selected filters.
   *
   * @param selectedFilters - Selected filter parameters
   * @param itemsPerPage - Number of items per page (optional)
   * @param currentPage - Current page number (optional)
   * @returns Observable<any> - Observable containing transaction history data
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTransactionHistory(selectedFilters: any, itemsPerPage?: number, currentPage?: number) {
    const { searchValue, dateRange, fromDate, toDate, status } = selectedFilters || {};
    const modifiedCurrentPage =
      currentPage !== null && currentPage !== undefined ? currentPage - 1 : undefined;

    let params: any = {
      search: searchValue !== null ? searchValue : undefined,
      dateRange,
      fromDate,
      toDate,
      itemsPerPage,
      status,
      currentPage: modifiedCurrentPage,
    };

    params = JSON.parse(JSON.stringify(params));

    return this.http.get(`${this.serverUrl}/eWallet/transactions`, { params });
  }

  /**
   * Add funds to the wallet.
   *
   * @param depositAmount - Amount to deposit
   * @returns Promise<boolean> - Promise that resolves to true if the operation is successful
   */
  addFunds(depositAmount: { depositAmount: number | string }) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.serverUrl}/eWallet/checkout`, depositAmount).subscribe(
        (res: any) => {
          if (res?.session) {
            if (this.isBrowser && res?.session.url?.trim() != '') {
              window.location.href = res?.session.url;
            }
            resolve(true);
          }
        },
        (err: any) => {
          reject(new Error(err));
        }
      );
    });
  }

  /**
   * Deposit funds into the wallet.
   *
   * @param transactionId - Transaction ID
   * @param sessionId - Session ID
   * @returns Observable<any> - Observable containing deposit information
   */
  deposit(transactionId: string | undefined, sessionId: string | undefined) {
    let params: any = {
      transactionId,
      sessionId,
    };
    params = JSON.parse(JSON.stringify(params));
    return this.http.get(`${this.serverUrl}/eWallet/deposit`, { params });
  }
}
