import { Component, ViewContainerRef } from '@angular/core';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { LocalStorageService } from '../../service';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-purchase-flow',
  templateUrl: './purchase-flow.component.html',
  styleUrls: ['./purchase-flow.component.scss'],
})
export class PurchaseFlowComponent {
  isLockScreenMode!: boolean; // Used for Customer Purchase Mode
  dialogRef: DialogComponent;

  constructor(
    private viewContainer: ViewContainerRef,
    private dashboardService: DashboardService,
    private _localStorageService: LocalStorageService
  ) {
    // Inject dialog component reference
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }


  /**
   * Continue the purchase flow.
   */
  continue() {
    if (this.isLockScreenMode != undefined) {
      // Update purchase flow status
      this.dashboardService._purchaseFlow.next(this.isLockScreenMode);
      // Close the dialog
      this.dialogRef.close.emit(true);
      // Set lock screen mode in local storage
      this._localStorageService.setItem('isLockScreenMode', JSON.stringify(this.isLockScreenMode));
    }
  }

  /**
   * Close the dialog.
   */
  close(): void {
    this.dialogRef.close.emit(false);
  }
}
