import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { AlertService } from '../../service/alert.service';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-payment-qr',
  templateUrl: './payment-qr.component.html',
  styleUrls: ['./payment-qr.component.scss'],
})
export class PaymentQrComponent implements OnInit {
  qrCodeDownloadLink!: SafeUrl;

  dialogRef: DialogComponent;
  data!: {
    paymentLink: string;
    totalSellingPrice: string | number;
  };
  inProgress = false;
  currencySymbol!: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private alertService: AlertService,
    private dashboardService: DashboardService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  ngOnInit(): void {
    // Initialize data from dialog context
    this.data = this.dialogRef?.context?.data;
  }

  /**
   * Close the dialog.
   */
  close() {
    // Emit data to close dialog
    this.dialogRef.close.emit(this.data);
  }

  /**
   * Handle change event of the URL.
   *
   * @param url New URL
   */
  onChangeURL(url: SafeUrl) {
    // Update QR code download link
    this.qrCodeDownloadLink = url;
  }

  /**
   * Continue the operation.
   */
  continue() {
    // Close the dialog
    this.close();
  }
}
