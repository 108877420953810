import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/features/dashboard/service/order.service';
import { Order } from '../../models';
import { LocalStorageService } from '../../service';
import { AlertService } from '../../service/alert.service';
import { DashboardService } from '../../service/dashboard.service';
import { SearchService } from '../../service/search/search.service';
import { SubscriptionsService } from '../../service/subscriptions.service';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
})
export class PaymentStatusComponent implements OnInit {
  transactionId!: string;
  orderId!: string;
  currentOrder!: Order;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  travellerList: any[] = [];
  reservedId!: string;
  dialogRef: DialogComponent;
  inProgress = false;
  success!: string | boolean | undefined;

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService,
    private router: Router,
    private alertService: AlertService,
    private orderService: OrderService,
    private subscriptionService: SubscriptionsService,
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private _searchService: SearchService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * Lifecycle hook called after component initialization.
   */
  ngOnInit() {
    const order = this.localStorageService.getItem('orderObj');
    if (order != null) {
      this.currentOrder = JSON.parse(order);
    }
    this.travellerList = this.currentOrder?.travellers || [];
  }

  /**
   * Add subscribers and subscriptions.
   */
  addSubscribersAndSubscriptions() {
    this.inProgress = true;
    const mappedArray = this.travellerList.map((traveller) => ({
      displayName: traveller.name,
      email: traveller.email,
      planId: traveller.selectedPlan?._id,
      sellingPrice: traveller.selectedPlan?.sellingPrice,
      country: this.currentOrder.destination?.name,
      reservedId: this.reservedId,
    }));

    this.subscriptionService
      .addSubscribersAndSubscriptions(mappedArray, this.transactionId)
      .subscribe(
        () => {
          this.inProgress = false;
        },
        (error: any) => {
          this.inProgress = false;
          this.close();
          this.alertService.error(error.error.message, error.status);
        }
      );
  }

  /**
   * Unreserve inventories.
   *
   * @param reservedId Reserved ID
   */
  unreservedInventories(reservedId: string) {
    this.inProgress = true;
    this.subscriptionService.unreservedInventories(reservedId).subscribe(
      () => {
        this.inProgress = false;
      },
      (error: any) => {
        this.inProgress = false;
        this.alertService.error(error.error.message, error.status);
      }
    );
  }

  /**
   * Close dialog and navigate based on transaction status.
   */
  close() {
    this.dialogRef.close.emit();
    if (
      (this.transactionId && this.transactionId != '') ||
      (this.orderId && this.orderId != '') ||
      this.success != undefined
    ) {
      this.orderService.resetOrder();
      this.localStorageService.removeItem('orderObj');
      this.localStorageService.removeItem('purchaseSource');
      this.localStorageService.removeItem('isLockScreenMode');
      this._searchService.setStepper(undefined);
      this.dashboardService._purchaseFlow.next(false);
      this.dashboardService.isShowDashboard.next(true);
      this.dashboardService.purchaseSource.next(undefined);

      this.router.navigate(['/'], { replaceUrl: true });
    } else {
      this.router.navigate(['/pay'], { replaceUrl: true });
    }
  }
}
