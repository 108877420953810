import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Support } from 'src/app/features/support/models';
import { SupportService } from 'src/app/features/support/support.service';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { AlertService } from '../../service/alert.service';

@Component({
  selector: 'app-support-request-info',
  templateUrl: './support-request-info.component.html',
  styleUrls: ['./support-request-info.component.scss'],
})
export class SupportRequestInfoComponent implements OnInit {
  dialogRef: DialogComponent;
  requestDetails!: Support;
  id!: string;
  title!: string;
  copyText = 'Copy';
  inProgress = false;
  statusArr: Array<string> = ['Resolved', 'In Progress', 'Pending', 'Unresolved'];
  selectedStatus!: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private supportService: SupportService,
    private alertService: AlertService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * Lifecycle hook called after component initialization.
   * Retrieves support request details when the component initializes.
   */
  ngOnInit(): void {
    this.id = this.dialogRef?.context?.id;
    this.title = this.dialogRef?.context?.title;
    if (this.id) {
      this.getSubscriberDetails(this.id);
    }
  }

  /**
   * Retrieves details of a support request by its ID.
   * @param id The ID of the support request to retrieve.
   */
  getSubscriberDetails(id: string) {
    this.inProgress = true;
    this.supportService.getSupportRequestById(id).subscribe(
      (result: any) => {
        this.inProgress = false;
        if (result?._id) {
          this.requestDetails = result;
        }
      },
      (err) => {
        this.inProgress = false;
        this.alertService.error(err.error.message, err.status);
      }
    );
  }

  /**
   * Handles the selection of a status for the support request.
   * @param value The selected status value.
   */
  onSelect(value: string) {
    this.selectedStatus = value;
    this.requestDetails.status = value;
  }

  /**
   * Saves the selected status for the support request.
   */
  saveStatus() {
    this.inProgress = true;
    this.supportService
      .updateSupportRequestStatus(this.requestDetails?._id, this.selectedStatus)
      .subscribe(
        (res: any) => {
          if (res) {
            this.inProgress = false;
            this.alertService.success(res.message, 'Support');
            this.dialogRef.close.emit(res);
          }
        },
        (err: any) => {
          this.inProgress = false;
          this.alertService.error(err.error.message, err.status);
        }
      );
  }

  /**
   * Closes the dialog.
   */
  close(): void {
    this.dialogRef.close.emit();
  }

  /**
   * Copies the user email to the clipboard.
   * @param event The mouse event triggering the copy action.
   * @param email The email to copy to the clipboard.
   */
  copyToClipboard(event: MouseEvent, email: string | undefined) {
    event.preventDefault();

    if (!email) {
      return;
    }
    navigator.clipboard.writeText(email);
  }
}
