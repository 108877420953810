/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Compiler,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit {
  constructor(
    private compiler: Compiler,
    private injector: Injector
  ) {}
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  @Input() template: any;
  @Input() context: any;
  @Input() cssClass: any;
  @Input() hasBackdrop: boolean | undefined = true;
  @Input() closeOutsideBackdrop: boolean | undefined = false;

  @ViewChild('backdrop', { read: ViewContainerRef }) backdrop!: ViewContainerRef;
  @ViewChild('dialogContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild('dialog', { read: ViewContainerRef }) dialog!: ViewContainerRef;
  componentRef: any;

  ngAfterViewInit(): void {
    import('src/app/global/shared.module').then(({ SharedModule }) => {
      this.compiler.compileModuleAsync(SharedModule).then((moduleFactory) => {
        const moduleRef: any = moduleFactory.create(this.injector);
        const componentFactory = moduleRef.instance.resolveComponent(this.template);
        this.componentRef = this.container.createComponent(
          componentFactory,
          undefined,
          moduleRef.injector
        );
        if (this.context && Object.keys(this.context).length > 0) {
          // Assign context properties to the component instance
          Object.keys(this.context).forEach((key) => {
            this.componentRef.instance[key] = this.context[key];
          });
        }
        if (this.cssClass) {
          // Add CSS class to dialog element
          this.dialog.element.nativeElement.className += ' ' + this.cssClass;
        }
      });
    });

    if (!this.hasBackdrop) {
      // Remove backdrop if not required
      this.backdrop.element.nativeElement.remove();
      if (this.backdrop.element?.nativeElement?.parentElement?.className) {
        // Add top-spacing class to parent element
        this.backdrop.element.nativeElement.parentElement.className = `${this.backdrop.element.nativeElement.parentElement.className} top-spacing`;
      }
    } else if (
      !this.cssClass?.includes('top-spacing') &&
      this.backdrop?.element?.nativeElement?.parentElement?.className
    ) {
      // Add top-spacing class to backdrop parent element
      this.backdrop.element.nativeElement.parentElement.className = `${this.backdrop.element?.nativeElement.parentElement.className} top-spacing`;

      if (this.closeOutsideBackdrop) {
        // Add click event listener to close dialog on backdrop click
        const el: any = this.backdrop.element.nativeElement;
        el.addEventListener('click', () => {
          this.close.emit();
        });
      }
    }
  }
}
