import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpError } from 'src/app/features/dashboard/models';
import { ConfigData } from '../models';
import { BrowserService } from './browser.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  serverUrl: string | undefined;
  isBrowser = false;

  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private router: Router,
    private configService: ConfigService
  ) {
    browserService.getIsBrowser().subscribe((isBrowser: boolean) => (this.isBrowser = isBrowser));

    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.serverUrl = configData?.trsConfig?.serverUrl;
    });
  }

  /**
   * Subscribes to a plan.
   *
   * @param reservedId The reserved ID
   * @param invoiceName Name of the invoice
   * @param invoiceEmail Email for the invoice
   * @param travellers Array of traveller details
   * @returns Promise resolving to true if successful
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribePlan(reservedId: any, invoiceName: string, invoiceEmail: string, travellers: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.serverUrl}/checkout?reservedId=${reservedId}&isPaymentLink=false&invoiceName=${invoiceName}&invoiceEmail=${invoiceEmail}`,
          travellers
        )
        .subscribe(
          (res: any) => {
            if (res) {
              if (this.isBrowser && res?.payment_url?.trim() != '') {
                window.location.href = res?.payment_url;
              }
              resolve(true);
            }
          },
          (err: any) => {
            reject(new HttpError(err));
          }
        );
    });
  }

  /**
   * Checks availability of eSIMs for the provided travellers.
   *
   * @param travellers Array of traveller details
   * @returns Observable of the eSIM availability check
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkEsimAvailability(travellers: any) {
    return this.http.post(`${this.serverUrl}/subscriptions/checkEsimAvailabilty`, travellers);
  }

  /**
   * Adds subscribers and subscriptions.
   *
   * @param travellers Array of traveller details
   * @param transactionId Optional transaction ID
   * @returns Observable of the operation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addSubscribersAndSubscriptions(travellers: any, transactionId?: any) {
    return this.http.post(
      `${this.serverUrl}/subscriptions/AddSubscribersAndSubscriptions?transactionId=${transactionId}`,
      travellers
    );
  }

  /**
   * Unreserves inventories.
   *
   * @param reservedId The reserved ID
   * @returns Observable of the unreserve operation
   */
  unreservedInventories(reservedId: any) {
    return this.http.delete(`${this.serverUrl}/inventory/unReserve?reservedId=${reservedId}`);
  }

  /**
   * Purchases a plan using e-wallet.
   *
   * @param invoiceName Name of the invoice
   * @param invoiceEmail Email for the invoice
   * @param travellers Array of traveller details
   * @returns Observable of the purchase operation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  purchasePlanByWallet(invoiceName: string, invoiceEmail: string, travellers: any) {
    return this.http.post(
      `${this.serverUrl}/eWallet/transactions?invoiceName=${invoiceName}&invoiceEmail=${invoiceEmail}`,
      travellers
    );
  }

  /**
   * Generates a payment link.
   *
   * @param travellers Array of traveller details
   * @param invoiceName Name of the invoice
   * @param invoiceEmail Email for the invoice
   * @param reservedId Optional reserved ID
   * @returns Observable of the payment link generation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generatePaymentLink(
    travellers: any,
    invoiceName: string,
    invoiceEmail: string,
    reservedId?: any
  ) {
    return this.http.post(
      `${this.serverUrl}/checkout?reservedId=${reservedId}&isPaymentLink=true&invoiceName=${invoiceName}&invoiceEmail=${invoiceEmail}`,
      travellers
    );
  }

  /**
   * Sends a payment link via email.
   *
   * @param data Data object containing email details
   * @returns Observable of the email sending operation
   */
  sendPaymentLinkViaEmail(data: { email: string; paymentId: string }) {
    return this.http.post(`${this.serverUrl}/payments/link/email`, data);
  }

  /**
   * Cancels a payment link.
   *
   * @param data Data object containing payment link details
   * @returns Observable of the cancellation operation
   */
  cancelPaymentLink(data: { paymentId: string }) {
    return this.http.put(`${this.serverUrl}/payments/link/cancel`, data);
  }
}
