import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/global/service';
import { ConfigService } from 'src/app/global/service/config.service';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  serverUrl: string | undefined;

  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService,
    private configService: ConfigService
  ) {
    // Subscribe to get local configuration
    configService.getLocalConfig().subscribe((configData: any) => {
      // Set server URL from configuration or fallback to default environment URL
      this.serverUrl = configData?.trsConfig?.serverUrl;
    });
  }

  /**
   * Get all support requests.
   *
   * @param selectedFilters - Selected filters
   * @param itemsPerPage - Number of items per page
   * @param currentPage - Current page number
   * @returns An observable of the HTTP response containing support requests
   */
  getAllSupportRequests(selectedFilters?: any, itemsPerPage?: any, currentPage?: any) {
    const { searchValue, dateRange, fromDate, toDate } = selectedFilters || {};
    const modifiedCurrentPage =
      currentPage !== null && currentPage !== undefined ? currentPage - 1 : undefined;

    let params: any = {
      search: searchValue !== null ? searchValue : undefined,
      dateRange,
      fromDate,
      toDate,
      itemsPerPage,
      currentPage: modifiedCurrentPage,
    };
    params = JSON.parse(JSON.stringify(params));
    return this.http.get(`${this.serverUrl}/support-requests`, { params });
  }

  /**
   * Get a support request by ID.
   *
   * @param requestId - ID of the support request
   * @returns An observable of the HTTP response containing the support request
   */
  getSupportRequestById(requestId: string) {
    return this.http.get(`${this.serverUrl}/support-requests/${requestId}`);
  }

  /**
   * Update the status of a support request.
   *
   * @param requestId - ID of the support request
   * @param statusValue - New status value
   * @returns An observable of the HTTP response
   */
  updateSupportRequestStatus(requestId: string, statusValue: string) {
    return this.http.put(`${this.serverUrl}/support-requests/${requestId}`, {
      status: statusValue,
    });
  }
}
