import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  private renderer!: Renderer2;
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Checks and assigns the logo URL.
   *
   * @param configData Configuration data object
   * @returns Promise resolving to the logo URL if valid, otherwise undefined
   */
  async checkAndAssignLogoUrl(imageUrl: string | undefined): Promise<string | undefined> {
    // Handle cases where imageUrl is undefined or an empty string
    if (!imageUrl) {
      return undefined; // or return a default value if needed
    }

    return new Promise((resolve) => {
      // Use Image object to check if the URL points to a valid image
      const img = new Image();
      img.onload = () => {
        // URL points to a valid image, resolve with the value
        resolve(imageUrl);
      };
      img.onerror = () => {
        // URL does not point to a valid image, resolve with undefined
        resolve(undefined);
      };

      // Set the source to trigger the image load event
      img.src = imageUrl;
    });
  }

  /**
   * Converts an image URL to a Base64 string.
   *
   * @param imageUrl URL of the image to convert
   * @returns Promise resolving to the Base64 string representation of the image
   */
  convertImageUrlToBase64(imageUrl?: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // Ensure imageUrl is defined and is a string
      if (!imageUrl) {
        reject(new Error('Image URL is required'));
        return;
      }

      // Fetch the image data
      this.http.get(imageUrl, { responseType: 'blob' }).subscribe(
        (blob: Blob) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            // Read the image data as a Base64 string
            const base64Data = reader.result as string;
            resolve(base64Data);
          };

          reader.onerror = () => {
            reject(new Error('Error reading image data.'));
          };

          // Read the Blob as a Base64 string
          reader.readAsDataURL(blob);
        },
        (error) => {
          reject(new Error(`Error fetching image: ${error.message}`));
        }
      );
    });
  }

  /**
   * Updates the favicon of the application.
   *
   * @param url The URL of the favicon
   * @param defaultUrl The default URL of the favicon
   */
  updateFavicon(url: string | undefined, defaultUrl: string): void {
    const faviconElement = this._document.getElementById('appFavicon');

    if (!faviconElement) {
      console.error('Favicon element with ID "appFavicon" not found.');
      return;
    }

    // If the URL is undefined or an empty string, use the default URL
    const validUrl = url?.trim() ? url : defaultUrl;

    // Use Image object to check if the URL points to a valid image
    const img = new Image();
    img.onload = () => {
      // URL points to a valid image, update the favicon with the dynamic URL
      this.renderer.setAttribute(faviconElement, 'href', validUrl);
    };
    img.onerror = () => {
      // URL does not point to a valid image, use the default URL
      this.renderer.setAttribute(faviconElement, 'href', defaultUrl);
    };

    // Set the source to trigger the image load event
    img.src = validUrl;
  }
}
