import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BrowserService } from '../browser.service';
import { ConfigService } from '../config.service';
import { LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private isBrowser = false;
  constructor(
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private browserService: BrowserService,
    private navigationService: NavigationService,
    private configService: ConfigService
  ) {
    browserService.getIsBrowser().subscribe((isBrowser: boolean) => {
      this.isBrowser = isBrowser;
    });
  }

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.isBrowser) {
      return true;
    }
    const token = this._localStorageService.getToken();

    if (token) {
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      const check = tokenDecode.user.roles;
      if (
        (check.includes('admin') || check.includes('agent')) &&
        !this._tokenExpiration(tokenDecode.exp)
      )
        return true;
    }

    this.configService.clearLocalStorage();
    this.navigationService.navigateToSignInWithQueryParams();
    return false;
  }

  // token expiration
  private _tokenExpiration(expiration: number): boolean {
    return Math.floor(new Date().getTime() / 1000) >= expiration;
  }
}
