import { Injectable } from '@angular/core';
import { DialogService } from '../component/dialog/dialog.service';
import { AlertComponent } from '../dialog/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialogService: DialogService) {}

  /**
   * Display an error alert message.
   *
   * @param message The error message to display.
   * @param statusCode The status code of the error (optional).
   * @param title The title of the error message (optional).
   * @returns A promise resolving to the result of opening the modal.
   */
  error(message: string, statusCode?: number, title?: string) {
    let customTitle: string;

    if (statusCode && statusCode == 401) {
      customTitle = 'Info';
    } else if (title) {
      customTitle = title;
    } else {
      customTitle = 'Error';
    }

    return this.dialogService.openModal(AlertComponent, {
      cssClass: 'modal-sm',
      context: { title: customTitle, body: message },
    });
  }

  /**
   * Display a warning alert message.
   *
   * @param message The warning message to display.
   * @param title The title of the warning message (optional).
   * @returns A promise resolving to the result of opening the modal.
   */
  warn(message: string, title?: string) {
    const customTitle: string = title ?? 'Go Back !';
    return this.dialogService.openModal(AlertComponent, {
      cssClass: 'modal-sm',
      context: { title: customTitle, body: message },
    });
  }

  /**
   * Display a warning alert message with a default title.
   *
   * @param message The warning message to display.
   * @returns A promise resolving to the result of opening the modal.
   */
  warning(message: string) {
    const customTitle = 'Warning';
    return this.dialogService.openModal(AlertComponent, {
      cssClass: 'modal-sm',
      context: { title: customTitle, body: message },
    });
  }

  /**
   * Display a success alert message.
   *
   * @param message The success message to display.
   * @param type The type of the success message (optional).
   * @returns A promise resolving to the result of opening the modal.
   */
  success(message: string, type?: string) {
    return this.dialogService.openModal(AlertComponent, {
      cssClass: 'modal-sm',
      context: { title: 'Successful!', body: message, type: type },
    });
  }
}
