import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { WalletService } from 'src/app/features/wallet/service/wallet.service';
import { User } from '../../models';
import { LocalStorageService } from '../../service';
import { AlertService } from '../../service/alert.service';
import { DashboardService } from '../../service/dashboard.service';
import { SearchService } from '../../service/search/search.service';
import { UserService } from '../../service/user.service';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.scss'],
})
export class TransactionStatusComponent implements OnInit {
  transactionId!: string;
  sessionId!: string;
  transactionData!: {
    message: string;
    walletDeposit: {
      walletBalance: string;
      currency: string;
      amountDeposited: number;
    };
  };
  dialogRef: DialogComponent;
  inProgress = false;
  userDetails!: User;
  walletInfo!: {
    from: string;
    amount: string | number;
  };

  buttons = [
    {
      walletInfoCondition: 'wallet',
      buttons: [
        { text: 'Back to Wallet', value: 'wallet' },
        { text: 'Retry Payment', value: 'retry' },
        // Add more buttons specific to wallet
      ],
    },
    {
      walletInfoCondition: 'purchase',
      buttons: [
        { text: 'Back to Payment', value: 'purchase' },
        { text: 'Retry Payment', value: 'retry' },
        // Add more buttons specific to purchase
      ],
    },
  ];

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService,
    private router: Router,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private _searchService: SearchService,
    private walletService: WalletService,
    private userService: UserService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);

    userService.getCurrentUser().subscribe((result) => {
      this.userDetails = result;
    });
  }

  /**
   * Lifecycle hook called after component initialization.
   */
  ngOnInit(): void {
    const walletInfo = this.localStorageService.getItem('addingBalanceFrom');
    if (walletInfo != null) {
      this.walletInfo = JSON.parse(walletInfo);
    }
    if (this.transactionData?.walletDeposit) {
      this.userDetails.walletBalance = parseFloat(
        this.transactionData?.walletDeposit?.walletBalance
      );
      this.userService.setCurrentUser(this.userDetails);
    }
  }

  /**
   * Close dialog and navigate based on type.
   *
   * @param type Type of action
   */
  close(type: string | undefined): void {
    this.inProgress = true;

    if (type === 'retry') {
      this.navigateTo('wallet-payment-status');
      this.checkOut();
    } else {
      // Check this.walletInfo value and navigate accordingly
      if (this.walletInfo?.from === 'wallet') {
        this.navigateTo('wallet');
      } else {
        this.navigateTo('pay');
      }
      this.localStorageService.removeItem('addingBalanceFrom');
    }

    this.dialogRef.close.emit();
  }

  /**
   * Navigate to specified destination.
   *
   * @param destination Destination to navigate
   */
  private navigateTo(destination: 'wallet' | 'pay' | 'wallet-payment-status'): void {
    this.router.navigate([`/${destination}`], { replaceUrl: true });
  }

  /**
   * Perform checkout process.
   */
  checkOut() {
    const amountObj = {
      depositAmount: this.walletInfo?.amount,
    };
    this.inProgress = true;
    this.walletService.addFunds(amountObj).catch((err: any) => {
      this.alertService.error(err.error.message, err.status);
      this.inProgress = false;
    });
  }
}
