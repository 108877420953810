import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from 'src/app/global/component/dialog/dialog.component';

@Component({
  selector: 'app-walkthrough-video',
  templateUrl: './walkthrough-video.component.html',
  styleUrls: ['./walkthrough-video.component.scss'],
})
export class WalkthroughVideoComponent {
  dialogRef: DialogComponent;

  constructor(
    private viewContainer: ViewContainerRef,
    private router: Router
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * Closes the dialog.
   */
  close() {
    this.dialogRef.close.emit();

    // Remove 'walkthrough=true' from current URL
    const urlWithoutParams = this.router.url.split('?')[0];
    this.router.navigateByUrl(urlWithoutParams);
  }
}
