import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appEllipsis]',
})
export class EllipsisDirective implements AfterViewInit, OnChanges {
  @Input() limit = 50;
  @Input() limitWeb = 20;
  @Input() limitTab = 15;
  @Input() limitTabSm = 10;
  @Input() limitMob = 100;
  @Input() placement = 'top';
  @Input() delay = '300';
  @Input() offset = '0';
  @Input() adjustLimit = false;

  tooltip!: HTMLElement | undefined;
  tooltipTitle!: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.applyEllipsis();
  }

  ngOnChanges() {
    this.applyEllipsis();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    // Hide the tooltip when the mouse leaves the element
    this.handleTooltipVisibility();
  }

  @HostListener('click', ['$event'])
  onClick() {
    // Hide the tooltip on click and manage its visibility
    this.handleTooltipVisibility();
  }

  // Helper method to handle tooltip visibility
  private handleTooltipVisibility() {
     if (this.tooltip) {
      this.hide(); // or any logic to hide the tooltip
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Hide the tooltip if clicking outside the element
    if (this.tooltip && !this.el.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  private applyEllipsis() {
    const text = this.el.nativeElement.innerText;
    let limitToUse = this.limit;

    const screenWidth =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth <= 1900) {
      limitToUse = this.limitWeb;
    }

    if (screenWidth <= 1199) {
      limitToUse = this.limitTab;
    }

    if (screenWidth <= 991) {
      limitToUse = this.limitTabSm;
    }

    if (screenWidth < 575) {
      limitToUse = this.limitMob;
    }

    if (text.length > limitToUse) {
      this.tooltipTitle = text;
      const truncatedText = text.slice(0, limitToUse);
      this.renderer.setProperty(this.el.nativeElement, 'innerText', truncatedText + '...');
    }
  }

  private show() {
    if (this.tooltipTitle) {
      this.create();
      this.setPosition();
      this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
    }
  }

  private hide() {
    if (this.tooltip) {
      this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
      window.setTimeout(() => {
        if (this.tooltip) {
          this.renderer.removeChild(document.body, this.tooltip);
          this.tooltip = undefined;
        }
      }, parseInt(this.delay));
    }
  }

  private create() {
    this.tooltip = this.renderer.createElement('span');
    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipTitle));

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  private setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip?.getBoundingClientRect();
    const scrollPos =
      window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top, left;

    if (this.placement === 'top' && tooltipPos) {
      top = hostPos.top - tooltipPos.height - parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom' && tooltipPos) {
      top = hostPos.bottom + parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - parseInt(this.offset);
    }

    if (this.placement === 'right' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + parseInt(this.offset);
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
