import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ConfigData } from '../models';
import { ConfigService } from './config.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  serverUrl: string | undefined;

  private _currentUser$ = new BehaviorSubject<any>(undefined);

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    private configService: ConfigService
  ) {
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.serverUrl = configData?.trsConfig?.serverUrl;
    });
  }

  getUserDetails() {
    return this.http.get(`${this.serverUrl}/users/me`);
  }

  getCurrentUser() {
    return this._currentUser$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCurrentUser(userDetails: any) {
    this._currentUser$.next(userDetails);
  }
}
