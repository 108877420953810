export interface DashboardDetails {
  [key: string]: string | number | undefined;
  revenue?: string;
  totalPlansSold?: number;
  profit?: string;
  totalNumberOfTravellers?: number;
  revenuePercent?: string;
  plansSoldPercent?: string;
  profitPercent?: string;
  numberOfTravellersPercent?: string;
}
