<div class="row thanks-modal-outer-div">
  <div class="col-12">
    <div class="svg-container">
      <svg
        class="success-svg"
        width="132"
        height="132"
        viewBox="0 0 132 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle class="circle-svg-primary" cx="66" cy="66" r="66" fill="#6365EF" fill-opacity="0.1" />
        <circle cx="65.999" cy="65.999" r="48.7451" fill="url(#paint0_linear_156_9122)" />
        <path
          d="M60.108 71.491L78.772 52.827C79.352 52.247 80.0901 51.957 80.9864 51.957C81.8827 51.957 82.6208 52.247 83.2008 52.827C83.7807 53.4069 84.0707 54.145 84.0707 55.0413C84.0707 55.9376 83.7807 56.6758 83.2008 57.2557L62.3224 78.1342C61.6897 78.7668 60.9515 79.0832 60.108 79.0832C59.2644 79.0832 58.5263 78.7668 57.8936 78.1342L49.6688 69.9093C49.0888 69.3294 48.7988 68.5912 48.7988 67.6949C48.7988 66.7986 49.0888 66.0605 49.6688 65.4806C50.2487 64.9006 50.9868 64.6106 51.8831 64.6106C52.7794 64.6106 53.5176 64.9006 54.0975 65.4806L60.108 71.491Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_156_9122"
            x1="92.3127"
            y1="17.2539"
            x2="50.0382"
            y2="109.999"
            gradientUnits="userSpaceOnUse"
          >
            <stop class="stop-svg-primary" stop-color="#6365EF" />
            <stop class="stop-svg-primary" offset="1" stop-color="#6365EF" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="card shadow-card"></div>
    <div class="card">
      <div class="card-body px-0">
        <div class="row mt-3">
          <div class="col-12 m-auto">
            <div class="thanks-modal-inner-div mt-3">
              <h4 class="text-center mb-sm-4 mb-3 heading">Order Placed!</h4>

              <ng-container>
                <p class="text-center fw-normal m-0 sub-heading">Your order placed successful.</p>
              </ng-container>

              <ng-container>
                <p class="text-center description mb-1">
                  We will assign a subscription once the customer make the payment.
                </p>
              </ng-container>
            </div>

            <div class="col-11 m-auto mt-3 mb-2">
              <div class="d-flex align-items-center justify-content-center">
                <button
                  type="submit"
                  class="btn btn-setting mb-1 rounded-pill w-100"
                  (click)="close()"
                >
                  <p class="mb-0 flex-fill text-white text-center">Home</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
