// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useRegionSpecificUrls: false,
  lookupServerUrl: 'https://dev-lookup.glowingbud.xyz',
  secretKey: '5e5361ea9a3ad5cb817d6bf2eb2c08c94db5be2559d22896bf966db374747a95',
  defaultTrsWebAppUrl: 'https://dev-gb.travel.user.webapp.penpenny.xyz',
  usServerUrl: 'https://api.travel.glowingbud.com',
  euServerUrl: 'https://eu-api.travel.glowingbud.com',
  defaultServerUrl: 'https://dev-api.gb.travel.penpenny.xyz',
  environmentName: 'development',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
