import { SafeHtml } from '@angular/platform-browser';

export interface Widget {
  title: string;
  titleIcon: string;
  detailsType: string;
  detailsKey: string;
  graphIcon: SafeHtml;
  showFooter: boolean;
  increase: string;
}
