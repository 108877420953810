import { Location } from '@angular/common';
import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { DialogService } from '../../component/dialog/dialog.service';
import { LocalStorageService } from '../../service';
import { DashboardService } from '../../service/dashboard.service';
import { SearchService } from '../../service/search/search.service';
import { OrderService } from './../../../features/dashboard/service/order.service';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
})
export class OrderStatusComponent {
  dialogRef: DialogComponent;

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private orderService: OrderService,
    private _searchService: SearchService,
    private location: Location
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * Close the dialog and reset order-related data.
   */
  close() {
    this.dialogRef.close.emit(); // Close dialog
    // Reset order-related data
    this.orderService.resetOrder();
    this.localStorageService.removeItem('orderObj');
    this.localStorageService.removeItem('purchaseSource');
    this.localStorageService.removeItem('isLockScreenMode');
    this._searchService.setStepper(undefined);
    this.dashboardService._purchaseFlow.next(false);
    this.dashboardService.isShowDashboard.next(true);
    this.dashboardService.purchaseSource.next(undefined);
    this.router.navigate(['/'], { replaceUrl: true }); // Navigate to home page
  }
}
