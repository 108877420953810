import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { DialogService } from '../../component/dialog/dialog.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  title = 'Something went wrong!';
  body = 'Oops, it seems like we have run into an error. Please rectify the problems and try again';
  dialogRef: DialogComponent;
  titleArr: string[] = ['Successful!', 'Error', 'Info', 'Warning'];
  type!: string;
  buttonGroup = [
    { cssClass: 'btn-secondary', title: 'Back', value: false },
    { cssClass: 'btn-secondary', title: 'Cancel', value: 'close' },
    { cssClass: 'ms-auto btn-danger', title: 'Try Again', value: false },
    { cssClass: 'ms-auto btn-danger', title: 'Go Back', value: 'back' },
    {
      cssClass: 'ms-auto btn-danger',
      title: 'Clear and Go Back!',
      value: 'back',
    },
    { cssClass: 'ms-auto', title: 'Close', value: false },
    { cssClass: 'ms-auto', title: 'Continue', value: false },
    { cssClass: 'ms-auto', title: 'Upload Another', value: false },
  ];
  routeUrl!: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService,
    private router: Router
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);

    const routerState: RouterStateSnapshot = router.routerState.snapshot;
    this.routeUrl = routerState.url;
  }

  /**
   * Lifecycle hook called after component initialization.
   */
  ngOnInit(): void {
    // Initialize component properties based on dialog context
    this.title = this.dialogRef?.context?.title;
    this.body = this.dialogRef?.context?.body;
    this.type = this.dialogRef?.context?.type;

    // Adjust title and body based on type
    if (this.type == 'Support') {
      this.title = 'Ticket Status Changed Successfully !';
      this.body = `<span class="text-opacity">Ticket status has been changed to</span> <span class="text-dark fw-bold">"${this.body}"</span>`;
    }
    if (this.type == 'invoice-download') {
      this.body = 'Invoice has been downloaded successfully!';
    }
    if (this.type == 'email-invoice') {
      this.body = `<p class="mb-1">Purchase Invoice successfully sent to customer email</p> <p class="text-dark fw-bold w-100 d-block text-break">"${this.body}"</p>`;
    }
    if (this.type == 'signup-completion') {
      this.title = 'You have successfully signed up on the platform!';
      this.body = 'Let’s get started by signing in below.';
    }

    // Adjust buttonGroup based on title
    switch (true) {
      case this.title == 'Error':
        this.buttonGroup = this.buttonGroup.filter(
          (x) => x.title == 'Back' || x.title == 'Try Again'
        );
        break;
      case this.title == 'Go Back !' || this.title == 'Clear and Go Back!':
        this.buttonGroup = this.buttonGroup.filter(
          (x) =>
            x.title == 'Cancel' || (x.title == 'Go Back' && x.cssClass != 'ms-auto btn-warning')
        );

        break;
      case this.title == 'Cancel !':
        this.buttonGroup = this.buttonGroup.filter((x) => x.title == 'Cancel');
        break;
      case this.title == 'Successful!' ||
        (this.title == 'Ticket Status Changed Successfully !' && this.type == 'Support'):
        this.buttonGroup = this.buttonGroup.filter(
          (x) => x.title == 'Continue' || x.title == 'Upload Another'
        );
        break;
      case this.title == 'Warning':
        this.buttonGroup = this.buttonGroup.filter((x) => x.title == 'Close');
        break;
    }
  }

  /**
   * Determines the appropriate button classes based on the `title` and `type` properties.
   * The classes dictate the appearance and style of the button.
   * @returns An object with class names and conditions.
   */
  getButtonClasses(): { [key: string]: boolean } {
    return {
      'btn-success':
        this.title === 'Successful!' ||
        this.type === 'Support' ||
        this.type === 'signup-completion',
      'btn-primary': this.title === 'Info',
      'btn-full-width w-100':
        this.title === 'Successful!' || this.title === 'Info' || this.type === 'Support',
      'btn-danger w-100': this.title === 'Error' || this.title === 'Cancel !',
    };
  }

  /**
   * Handles the button click logic to determine the close type.
   * Sets the close type based on the `title` and `type` properties.
   * Calls the `close` method with the appropriate close type.
   */
  handleClose(): void {
    let closeType: string | null = null;
    if (this.title === 'Error') {
      closeType = 'error';
    } else if (this.title === 'Cancel !') {
      closeType = 'back';
    } else if (this.type === 'signup-completion') {
      closeType = 'signin';
    }
    this.close(closeType);
  }

  /**
   * Returns the appropriate button text based on the `title` and `type` properties.
   * @returns A string representing the button text.
   */
  getButtonText(): string {
    if (this.title === 'Cancel !') {
      return 'Cancel';
    }
    return this.type === 'signup-completion' ? 'Sign In' : 'Close';
  }

  /**
   * Closes the dialog and navigates based on the provided item.
   *
   * @param item - An optional parameter that determines the navigation and close behavior.
   *               - 'back': Navigates to the home page and emits 'back' event.
   *               - 'signin': Navigates to the sign-in page and emits 'signin' event.
   *               - Any other value or undefined: Checks for 'wallet-payment-status' in the route URL
   *                 and navigates to the wallet page if found, then emits a general close event.
   */
  close(item?: string | boolean | null): void {
    switch (item) {
      case 'back':
        this.router.navigate(['/']);
        this.dialogRef.close.emit('back');
        break;
      case 'signin':
        // Replace the current URL in history with the sign-in URL to prevent back navigation
        this.router.navigate(['auth/signin'], { replaceUrl: true });
        this.dialogRef.close.emit('signin');
        break;
      default:
        if (this.routeUrl.includes('wallet-payment-status')) {
          this.router.navigate(['/wallet'], { replaceUrl: true });
        }
        this.dialogRef.close.emit();
        break;
    }
  }
}
