import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

interface RegexMap {
  [key: string]: RegExp;
}

@Directive({
  selector: '[appInputValidator]',
})
export class InputValidatorDirective {
  @Input('appInputValidator') validationType!: string;

  private regexMap: RegexMap = {
    alphabetsNoSpace: /^[a-zA-Z]*$/, // Alphabets without space
    alphabets: /^[a-zA-Z\s]*$/, // Alphabets with spaces
    numbers: /^[\d\s]*$/, // Numbers with spaces
    alphanumerics: /^[a-zA-Z\d\s]*$/, // Alphanumeric with spaces
    alphanumericSpecial: /^[a-zA-Z\d\s\-,.&]*$/, // Alphanumeric with spaces and special characters like -, ., &
  };

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const regex = this.regexMap[this.validationType];

    if (regex) {
      const sanitizedValue = value
        .split('')
        .filter((char) => regex.test(char))
        .join('');

      if (inputElement.value !== sanitizedValue) {
        inputElement.value = sanitizedValue; // Update input value based on the specified validation type
        this.control.control?.setValue(sanitizedValue); // Update form control value
      }
    }
  }
}
