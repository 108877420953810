<div class="payment-fail-success-modal">
  <div class="card" *ngIf="!inProgress; else spinner">
    <div class="card-body p-0">
      <div class="plan-div">
        <div class="m-auto">
          <div class="d-flex justify-content-between align-items-center">
            <p
              class="payment-status-heading"
              [ngClass]="{
                'plan-header-success': transactionId && transactionId !== '',
                'plan-header-failed': sessionId && sessionId !== ''
              }"
            >
              {{ transactionId && transactionId !== '' ? 'New Balance ' : 'Payment Failure' }}
            </p>
            <div>
              <svg
                class="cursor-pointer"
                (click)="close(undefined)"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="black"
                  stroke-opacity="0.5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="d-flex wallet-balance flex-column">
          <p class="balance-amount mb-2" *ngIf="transactionData">
            {{ transactionData.walletDeposit.currency | currencySymbol
            }}{{ transactionData?.walletDeposit?.walletBalance }}
          </p>
          <p class="balance-label">Available Balance</p>
        </div>

        <div class="d-flex success-fail-div">
          <div class="svg-icon">
            <svg
            class="success-svg"
              width="132"
              height="132"
              viewBox="0 0 132 132"
              fill="none"
              *ngIf="transactionId !== ''; else errorSvg"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle class="circle-svg-primary" cx="66" cy="66" r="66" fill="#6365EF" fill-opacity="0.1" />
              <circle cx="65.999" cy="65.999" r="48.7451" fill="url(#paint0_linear_1303_44975)" />
              <path
                d="M60.108 71.491L78.772 52.827C79.352 52.247 80.0901 51.957 80.9864 51.957C81.8827 51.957 82.6208 52.247 83.2008 52.827C83.7807 53.4069 84.0707 54.145 84.0707 55.0413C84.0707 55.9376 83.7807 56.6758 83.2008 57.2557L62.3224 78.1342C61.6897 78.7668 60.9515 79.0832 60.108 79.0832C59.2644 79.0832 58.5263 78.7668 57.8936 78.1342L49.6688 69.9093C49.0888 69.3294 48.7988 68.5912 48.7988 67.6949C48.7988 66.7986 49.0888 66.0605 49.6688 65.4806C50.2487 64.9006 50.9868 64.6106 51.8831 64.6106C52.7794 64.6106 53.5176 64.9006 54.0975 65.4806L60.108 71.491Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1303_44975"
                  x1="92.3127"
                  y1="17.2539"
                  x2="50.0382"
                  y2="109.999"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop class="stop-svg-primary" stop-color="#6365EF" />
                  <stop class="stop-svg-primary" offset="1" stop-color="#6365EF" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>

            <ng-template #errorSvg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="132"
                height="132"
                viewBox="0 0 132 132"
                fill="none"
              >
                <circle cx="66" cy="66" r="66" fill="#D92D20" fill-opacity="0.1" />
                <circle cx="65.999" cy="65.999" r="48.7451" fill="url(#paint0_linear_5399_17048)" />
                <mask
                  id="mask0_5399_17048"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="41"
                  y="41"
                  width="50"
                  height="50"
                >
                  <rect x="41" y="41" width="50" height="50" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_5399_17048)">
                  <path
                    d="M65.8333 76.25C66.4236 76.25 66.9184 76.0504 67.3177 75.6511C67.717 75.2518 67.9167 74.757 67.9167 74.1667C67.9167 73.5764 67.717 73.0816 67.3177 72.6823C66.9184 72.283 66.4236 72.0833 65.8333 72.0833C65.2431 72.0833 64.7483 72.283 64.349 72.6823C63.9497 73.0816 63.75 73.5764 63.75 74.1667C63.75 74.757 63.9497 75.2518 64.349 75.6511C64.7483 76.0504 65.2431 76.25 65.8333 76.25ZM63.75 67.9167H67.9167V55.4167H63.75V67.9167ZM65.8333 86.6667C62.9514 86.6667 60.2431 86.1198 57.7083 85.0261C55.1736 83.9323 52.9688 82.4479 51.0938 80.5729C49.2188 78.6979 47.7344 76.4931 46.6406 73.9583C45.5469 71.4236 45 68.7153 45 65.8333C45 62.9514 45.5469 60.2431 46.6406 57.7083C47.7344 55.1736 49.2188 52.9688 51.0938 51.0938C52.9688 49.2188 55.1736 47.7344 57.7083 46.6406C60.2431 45.5469 62.9514 45 65.8333 45C68.7153 45 71.4236 45.5469 73.9583 46.6406C76.4931 47.7344 78.6979 49.2188 80.5729 51.0938C82.4479 52.9688 83.9323 55.1736 85.0261 57.7083C86.1198 60.2431 86.6667 62.9514 86.6667 65.8333C86.6667 68.7153 86.1198 71.4236 85.0261 73.9583C83.9323 76.4931 82.4479 78.6979 80.5729 80.5729C78.6979 82.4479 76.4931 83.9323 73.9583 85.0261C71.4236 86.1198 68.7153 86.6667 65.8333 86.6667ZM65.8333 82.5C70.4861 82.5 74.4271 80.8854 77.6563 77.6563C80.8854 74.4271 82.5 70.4861 82.5 65.8333C82.5 61.1806 80.8854 57.2396 77.6563 54.0104C74.4271 50.7813 70.4861 49.1667 65.8333 49.1667C61.1806 49.1667 57.2396 50.7813 54.0104 54.0104C50.7813 57.2396 49.1667 61.1806 49.1667 65.8333C49.1667 70.4861 50.7813 74.4271 54.0104 77.6563C57.2396 80.8854 61.1806 82.5 65.8333 82.5Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_5399_17048"
                    x1="92.3127"
                    y1="17.2539"
                    x2="50.0382"
                    y2="109.999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D92D20" />
                    <stop offset="1" stop-color="#D92D20" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </ng-template>
          </div>
          <p
            class="mb-1 success-fail-msg-text"
            [ngClass]="{ 'success-msg': transactionId !== '', 'failed-msg': sessionId }"
          >
            {{ transactionId !== '' ? 'Successfully added!' : 'Fail to add amount!' }}
          </p>
          <div class="amount-added" *ngIf="transactionData">
            {{ transactionData.walletDeposit.currency | currencySymbol }}
            {{ transactionData?.walletDeposit?.amountDeposited ?? 0 }} added
          </div>
        </div>

        <div class="m-auto mt-2 mb-2">
          <div class="note-div text-start">
            <span class="note" *ngIf="transactionData && transactionId && transactionId !== ''">
              *Note: The amount was successfully added to the wallet. The new balance is
              {{ transactionData.walletDeposit.currency | currencySymbol
              }}{{ transactionData?.walletDeposit?.walletBalance }}
            </span>
            <span class="note" *ngIf="sessionId && sessionId !== ''">
              *Note: The amount was not added to the wallet. Please try again.
            </span>
          </div>
        </div>

        <div class="m-auto mt-sm-2 mt-3 mb-sm-4 retry-proceed-btn-div">
          <button
            type="submit"
            class="btn btn-proceed"
            (click)="close(transactionId && transactionId !== '' ? 'success' : 'retry')"
            *ngIf="walletInfo?.from === 'wallet'"
          >
            {{ transactionId && transactionId !== '' ? 'Back to Wallet' : 'Retry Payment' }}
          </button>
          <button
            type="submit"
            class="btn btn-proceed"
            (click)="
              close(
                transactionId && transactionId !== ''
                  ? 'success'
                  : sessionId && sessionId !== ''
                    ? 'retry'
                    : ''
              )
            "
            *ngIf="walletInfo?.from !== 'wallet'"
          >
            {{
              transactionId && transactionId !== ''
                ? 'Back to Payment'
                : sessionId && sessionId !== ''
                  ? 'Retry Payment'
                  : 'Back to Payment'
            }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Loading Spinner - Start -->
  <ng-template #spinner class="loader-align">
    <app-loading-spinner></app-loading-spinner>
  </ng-template>
  <!-- Loading Spinner - End -->
</div>
