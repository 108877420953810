import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appStatusColor]',
})
export class StatusColorDirective implements OnChanges {
  @Input('appStatusColor') textValue!: string;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['textValue']) {
      this.updateTextColor();
    }
  }

  private updateTextColor() {
    let color: string;

    switch (this.textValue) {
      case 'Pending':
        color = 'var(--color-pending)';
        break;
      case 'Resolved':
        color = 'var(--color-success)';
        break;
      case 'In Progress':
        color = 'var(--color-warning)';
        break;
      case 'Unresolved':
        color = 'var(--color-danger)';
        break;
      default:
        color = 'var(--color-dark)';
        break;
    }

    this.elementRef.nativeElement.style.color = color;
  }
}
