import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PlanDetails, Region } from 'src/app/global/models';
import { Destination, GlobalRegion, Order, Traveller } from 'src/app/global/models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public _CURRENT_BACK_STEP: string | undefined = undefined;
  public _TRAVELLER_LIST: any = [];
  private order: Order = {
    _id: 1,
    destination: {
      _id: undefined,
      name: '',
      isSelected: false,
      currentPage: 1,
    },
    travellers: [],
    orderSummary: {},
    invoiceDetails: {},
  };

  private paySubject = new Subject<void>();
  pay$ = this.paySubject.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  /**
   * Set the destination for the order.
   *
   * @param destinationData - Partial destination data to set.
   */
  setDestination(destinationData: Destination | Region | GlobalRegion | null) {
    if (destinationData) {
      // Directly assign the destinationData to this.order.destination
      this.order.destination = { ...destinationData };
    } else {
      this.order.destination = {};
    }
  }

  /**
   * Set the list of travellers for the order.
   *
   * @param travellers - List of travellers to set.
   */
  setTravellers(travellers: Traveller[]) {
    this.order.travellers = travellers;
  }

  /**
   * Set the current order.
   *
   * @param order - Order data to set.
   */
  setCurrentOrder(order: Order) {
    this.order = order;
  }

  /**
   * Set the reserved ID for the order.
   *
   * @param reservedId - Reserved ID to set.
   */
  setReservedId(reservedId: string | undefined) {
    this.order.reservedId = null;
    this.order.reservedId = reservedId;
  }

  /**
   * Set the selected plan for a specific traveller or common plan for all travellers.
   *
   * @param email - Email of the traveller (optional).
   * @param selectedPlan - Selected plan to set.
   */
  setSelectedPlan(email: string, selectedPlan: PlanDetails | null) {
    if (email) {
      const traveler = this.order?.travellers?.find((t: any) => t.email === email);
      if (traveler) {
        traveler.selectedPlan = selectedPlan;
      }
    } else {
      this.order.commonSelectedPlan = selectedPlan;
    }
  }

  /**
   * Set a common plan for all travellers.
   *
   * @param selectedPlan - Common plan to set.
   */
  setCommonPlan(selectedPlan: PlanDetails | null) {
    this.order.commonSelectedPlan = selectedPlan;
  }

  /**
   * Set a common plan for all travellers (alternative method).
   *
   * @param selectedPlan - Common plan to set.
   */
  setCommonPlanForAll(selectedPlan: PlanDetails | null) {
    this.order.commonPlan = selectedPlan;
  }

  /**
   * Set order summary details.
   *
   * @param totalBuyingPrice - Total buying price to set.
   * @param totalSellingPrice - Total selling price to set.
   * @param profitMargin - Profit margin to set.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOrderSummary(totalBuyingPrice: any, totalSellingPrice: any, profitMargin: any): void {
    this.order.orderSummary = {
      totalBuyingPrice: parseFloat(totalBuyingPrice.replace(/[^\d.]/g, '')) || 0,
      totalSellingPrice: parseFloat(totalSellingPrice.replace(/[^\d.]/g, '')) || 0,
      profitMargin: parseFloat(profitMargin.replace(/[^\d.]/g, '')) || 0,
    };
  }

  /**
   * Set invoice details for the order.
   *
   * @param invoiceDetails - Invoice details to set.
   */
  setInvoiceDetails(invoiceDetails: { email: string; name: string }) {
    this.order.invoiceDetails = invoiceDetails;
  }

  /**
   * Get the current order.
   *
   * @returns The current order.
   */
  getCurrentOrder(): Order {
    return this.order;
  }

  /**
   * Reset the order.
   */
  resetOrder() {
    this.order = {
      destination: {}, // Initialize destination with an empty object or other default values
      travellers: [],
      orderSummary: {},
    };
  }

  triggerPay() {
    this.paySubject.next();
  }

  /**
   * Determines the type of destination and returns an object indicating which type it is.
   *
   * @param destination - The destination to check.
   * @returns An object with properties isDestination, isRegion, and isGlobalRegion, or null if none match.
   */
  identifyDestinationType(destination: any) {
    const type = this.getType(destination);

    switch (type) {
      case 'country':
        return {
          isDestination: true,
          isRegion: false,
          isGlobalRegion: false,
          data: destination as Destination,
          type: 'country',
        };
      case 'region':
        return {
          isDestination: false,
          isRegion: true,
          isGlobalRegion: false,
          data: destination as Region,
          type: 'region',
        };
      case 'global':
        return {
          isDestination: false,
          isRegion: false,
          isGlobalRegion: true,
          data: destination as GlobalRegion,
          type: 'global',
        };
      default:
        return {
          isDestination: false,
          isRegion: false,
          isGlobalRegion: false,
          data: null,
          type: 'none',
        };
    }
  }

  getType(destination: any): string {
    return (destination as { type?: string }).type ?? 'none';
  }

  /**
   * Set the selected option for the order.
   */
  setSelectedOption(selectedOption: string) {
    this.order.selectedOption = selectedOption;
  }
}
