import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ConfigService } from '../config.service';
import { LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  serverUrl: string | undefined;
  constructor(
    private _localStorageService: LocalStorageService,
    private router: Router,
    private configService: ConfigService,
    private navigationService: NavigationService
  ) {
    configService.getLocalConfig().subscribe((configData: any) => {
      this.serverUrl = configData?.trsConfig?.serverUrl;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this._localStorageService.getToken();
    // Ensure serverUrl is defined before checking if the request URL starts with it
    const isApiUrl = this.serverUrl ? request.url.startsWith(this.serverUrl) : false;

    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }

    return next.handle(request).pipe(
      map((data) => data),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.configService.clearLocalStorage();
          this.navigationService.navigateToSignInWithQueryParams();
        }

        return throwError(() => err);
      })
    );
  }
}
