import { Component } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/features/dashboard/service/order.service';
import { ConfigData, User } from '../../models';
import { LocalStorageService } from '../../service';
import { ConfigService } from '../../service/config.service';
import { DashboardService } from '../../service/dashboard.service';
import { LogoService } from '../../service/logo.service';
import { SearchService } from '../../service/search/search.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  activeUrl = 'dashboard';
  show = false;
  sidebarMenuList: Array<{
    title: string;
    icon: SafeHtml;
    link: string;
    accessRole: string[];
    hasGroup: boolean;
    readOnly: boolean;
  }> = [];
  isDarkTheme = false;
  userDetails!: User;
  activeDashboardUrls: Array<string> = ['/order-summary', '/pay', '/wallet', '/qr'];
  logoUrl!: string | undefined;
  logoUrlTab!: string | undefined;

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private userService: UserService,
    private _searchService: SearchService,
    private orderService: OrderService,
    private localStorageService: LocalStorageService,
    private configService: ConfigService,
    private logoService: LogoService
  ) {
    userService.getCurrentUser().subscribe((result) => {
      this.userDetails = result;
      if (this.userDetails?.roles) {
        this.fetchNavBarMenuList(this.userDetails.roles);
      }

      this.configService.getLocalConfig().subscribe((configData: ConfigData) => {
        if (configData != undefined && configData != null) {
          this.checkAndAssignLogoUrl(configData);
        }
      });
    });
  }

  /**
   * Fetch navigation bar menu list based on user roles.
   *
   * @param roles User roles
   */
  fetchNavBarMenuList(roles: Array<string>) {
    this.sidebarMenuList = this.dashboardService.getNavBarMenus(roles);
  }

  /**
   * Show submenu.
   *
   * @param itemEl HTML element
   */
  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle('showMenu');
  }

  /**
   * Close sidebar.
   */
  closeSidebar() {
    if (this.show === true) {
      this.show = false;
    }
  }

  /**
   * Check if the given route URL is active.
   *
   * @param routeUrl Route URL
   * @returns Boolean indicating if the route is active
   */
  isActiveLink(routeUrl: string): boolean {
    return (
      this.router.isActive(routeUrl, true) ||
      (this.router.url.includes(routeUrl) && routeUrl != '/') ||
      (routeUrl == '/' && this.activeDashboardUrls.includes(this.router.url))
    );
  }

  /**
   * Clear selected filters and reset order.
   *
   * @param link Route link
   */
  clearSelectedFilters(link: string) {
    this._searchService.selectedFilters = null;
    this.orderService._CURRENT_BACK_STEP = undefined;
    this.orderService.resetOrder();
    this._searchService.setStepper(link);
    this.dashboardService.purchaseSource.next(undefined);
    this.localStorageService.removeItem('orderObj');
    if (link == '/') {
      this.dashboardService.isShowDashboard.next(true);
    }
  }

  /**
   * Check and assign logo URL.
   *
   * @param configData Configuration data
   */
  async checkAndAssignLogoUrl(configData: ConfigData) {
    this.logoUrl = await this.logoService.checkAndAssignLogoUrl(configData?.trsConfig?.logo);
    this.logoUrlTab = await this.logoService.checkAndAssignLogoUrl(
      configData?.trsConfig?.logoSquare
    );
  }

  /**
   * Handles image loading errors for the main logo or the logo tab.
   * If an error occurs, sets the respective logo URL to a fallback image URL.
   * @param type - The type of logo ('logo' for the main logo, 'logoTab' for the logo tab)
   */
  handleImageError(type: string) {
    const fallbackUrl =
      type === 'logo' ? '/assets/images/auth/logo.svg' : '/assets/images/auth/logo-tab.svg';
    if (type === 'logo') {
      this.logoUrl = fallbackUrl;
    } else {
      this.logoUrlTab = fallbackUrl;
    }
  }
}
