<div class="support-info-modal" *ngIf="!inProgress; else spinner">
  <div class="d-flex justify-content-between align-items-center pb-3">
    <p class="modal-header-text mb-0">{{ title }}</p>
    <button class="button-simple" (click)="close()">
      <svg
        class="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="black"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
  <div class="info-modal-body container">
    <ng-container *ngIf="title === 'Support Info'">
      <div class="row support-info-row d-flex align-items-center">
        <div class="col-5 px-0">
          <p class="subscriber-label-text">Support Ticket Number</p>
        </div>
        <div class="col-7 ps-0 d-flex justify-content-between align-items-center">
          <div
            class="subscriber-details-text text-truncate w-75"
            appEllipsis
            [limitTab]="20"
            [limitTabSm]="20"
          >
            <span
              *ngIf="requestDetails.ticket.length > 16; else noTicketTooltip"
              [tooltip]="requestDetails.ticket"
              placement="top"
              delay="100"
              offset="5"
              >{{ requestDetails.ticket }}</span
            >
            <ng-template #noTicketTooltip>
              {{ requestDetails.ticket }}
            </ng-template>
          </div>
          <button
            class="icon button-simple"
            (click)="copyToClipboard($event, requestDetails['ticket'])"
            [tooltip]="copyText"
            placement="top"
            delay="100"
            offset="5"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="#1C1B1F"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_2161_5534"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2161_5534)">
                <path
                  d="M4.16667 18.3332C3.70833 18.3332 3.31583 18.1701 2.98917 17.844C2.66306 17.5173 2.5 17.1248 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667ZM7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="row support-info-row d-flex align-items-center">
        <div class="col-5 px-0">
          <p class="subscriber-label-text">Name</p>
        </div>
        <div class="col-7 ps-0">
          <p class="subscriber-details-text text-truncate">
            <span
              *ngIf="requestDetails.name.length > 24; else noNameTooltip"
              [tooltip]="requestDetails.name"
              placement="top"
              delay="100"
              offset="5"
              appEllipsis
              [limit]="20"
              [limitTab]="25"
              [limitTabSm]="25"
              >{{ requestDetails.name }}</span
            >
            <ng-template #noNameTooltip>
              {{ requestDetails.name }}
            </ng-template>
          </p>
        </div>
      </div>

      <div class="row support-info-row d-flex align-items-center">
        <div class="col-5 px-0">
          <p class="subscriber-label-text">Email Address</p>
        </div>
        <div class="col-7 ps-0 d-flex justify-content-between align-items-center">
          <div
            class="subscriber-details-text text-truncate"
            appEllipsis
            [limit]="30"
            [limitTab]="25"
            [limitTabSm]="25"
          >
            <span
              *ngIf="requestDetails.email.length > 16; else noTooltip"
              [tooltip]="requestDetails.email"
              placement="top"
              delay="100"
              offset="5"
              >{{ requestDetails.email }}</span
            >
            <ng-template #noTooltip>
              {{ requestDetails.email }}
            </ng-template>
          </div>
          <button
            class="icon button-simple"
            (click)="copyToClipboard($event, requestDetails['email'])"
            [tooltip]="copyText"
            placement="top"
            delay="100"
            offset="5"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="#1C1B1F"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_2161_5534"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2161_5534)">
                <path
                  d="M4.16667 18.3332C3.70833 18.3332 3.31583 18.1701 2.98917 17.844C2.66306 17.5173 2.5 17.1248 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667ZM7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>

      <div class="row support-info-row d-flex align-items-center">
        <div class="col-5 px-0">
          <p class="subscriber-label-text">Message</p>
        </div>
        <div class="col-7 ps-0 d-flex align-items-center">
          <p
            class="subscriber-details-text text-overflow-ellipsis"
            appEllipsis
            [limit]="40"
            *ngIf="requestDetails.message.length > 20"
            [tooltip]="requestDetails.message"
            placement="top"
            delay="100"
            offset="0"
          >
            {{ requestDetails.message }}
          </p>
          <p class="subscriber-details-text" *ngIf="requestDetails.message.length <= 20">
            {{ requestDetails.message }}
          </p>
        </div>
      </div>

      <div class="row support-info-row d-flex align-items-center" *ngIf="title === 'Support Info'">
        <div class="col-5 px-0">
          <p class="subscriber-label-text">Order No.</p>
        </div>
        <div class="col-7 ps-0">
          <p class="subscriber-details-text">
            {{ requestDetails.orderId ?? 'N/A' }}
          </p>
        </div>
      </div>

      <div class="row support-info-row d-flex align-items-center" *ngIf="title === 'Support Info'">
        <div class="col-5 px-0">
          <p class="subscriber-label-text">Timestamp</p>
        </div>
        <div class="col-7 ps-0">
          <p class="subscriber-details-text word-space">
            {{ (requestDetails.created | date: "yyyy-MM-dd HH:mm:ss.SSS'Z'") ?? 'N/A' }}
          </p>
        </div>
      </div>

      <div class="row support-info-row d-flex align-items-center">
        <div class="col-5 px-0">
          <p class="subscriber-label-text">Status</p>
        </div>
        <div class="col-7 ps-0">
          <div class="subscriber-details-text word-break">
            <div class="custom-padding filter-div d-flex">
              <div
                class="lang-dropdown sg-font-small-regular d-flex align-items-center px-2 cursor-pointer"
                id="dropdownMenuLink-lang"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span id="languageTootltip" [appStatusColor]="requestDetails.status">{{
                  requestDetails.status
                }}</span>
                <span class="ms-auto">
                  <svg
                    class="down-arrow"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 1L5 5L1 1"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
              <div
                class="dropdown-menu lang-dropdown-option border-secondary custom-padding"
                aria-labelledby="dropdownMenuLink-lang"
              >
                <a
                  class="dropdown-item lang-dropdown-item sg-font-regular status-item"
                  *ngFor="let status of statusArr"
                  (click)="onSelect(status)"
                  >{{ status }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <br /><br />
  <div class="es-modal-footer d-flex pt-4">
    <button
      class="btn ms-auto w-100 support-button"
      [ngClass]="selectedStatus ? 'primary-bg' : 'btn-disabled'"
      (click)="saveStatus()"
      [disabled]="!selectedStatus"
    >
      Save Status
    </button>
  </div>
</div>

<!-- Loading Spinner - Start -->
<ng-template #spinner class="loader-align">
  <app-loading-spinner></app-loading-spinner>
</ng-template>
<!-- Loading Spinner - End -->
