import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tooltip]',
})
export class TooltipDirective {
  @Input('tooltip') tooltipTitle!: string;
  @Input() placement!: string;
  @Input() delay!: string;
  @Input() offset = '0';
  @Input() svgPath!: string;
  tooltip!: HTMLElement | undefined;

  // Add a flag to track the tooltip visibility
  private isTooltipVisible = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  // Handle click event to toggle tooltip visibility
  @HostListener('click') onClick() {
    if (this.tooltip?.innerText == 'Copy') {
      this.tooltip.innerText = 'Copied';
    } else if (this.isTooltipVisible) {
      this.hide();
    }

    if (window.innerWidth < 575) {
      if (!this.tooltip) {
        this.show();
      }
    }
  }

  // Handle document click to hide tooltip
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.tooltip && !this.el.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  show() {
    if (this.tooltipTitle) {
      this.create();
      this.setPosition();
      this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
      this.isTooltipVisible = true;
    }
  }

  hide() {
    if (this.tooltip) {
      this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      window.setTimeout(() => {
        if (self.tooltip) {
          self.renderer.removeChild(document.body, self.tooltip);
          self.tooltip = undefined;
          self.isTooltipVisible = false; // Set the flag to false
        }
      }, parseInt(self.delay));
    }
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    if (this.svgPath) {
      const img = this.renderer.createElement('img');
      img.setAttribute('src', this.svgPath);
      this.renderer.appendChild(this.tooltip, img);
      this.renderer.addClass(this.tooltip, 'ng-tooltip-light');
    }

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle) // textNode
    );

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    // Add a class based on tooltip content
    if (this.tooltipTitle === 'Download Invoice') {
      this.renderer.addClass(this.tooltip, 'download-tooltip');
    }

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip?.getBoundingClientRect();
    const scrollPos =
      window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top, left;

    if (this.placement === 'top' && tooltipPos) {
      top = hostPos.top - tooltipPos.height - parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom' && tooltipPos) {
      top = hostPos.bottom + parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - parseInt(this.offset);
    }

    if (this.placement === 'right' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + parseInt(this.offset);
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
