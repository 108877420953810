<div class="app-sidebar">
  <div class="sidebar-logo-container">
    <a class="sidebar-logo" routerLink="/" (click)="clearSelectedFilters('/')">
      <img
        class="logo-img d-xl-block d-none"
        [src]="logoUrl"
        alt="logo"
        (error)="handleImageError('logo')"
      />
      <img
        class="logo-img d-xl-none d-block"
        [src]="logoUrlTab"
        alt="logo"
        (error)="handleImageError('logoTab')"
      />
    </a>
  </div>
  <div class="sidebar-list-container">
    <div class="sidebar-list-menu">
      <div *ngFor="let menu of sidebarMenuList" class="" #itemEl>
        <div *ngIf="!menu.hasGroup" class="pt-0 pb-0">
          <a
            [routerLink]="menu.readOnly ? null : [menu.link]"
            [routerLinkActive]="['activeLink']"
            (click)="clearSelectedFilters(menu.link)"
            [routerLinkActiveOptions]="{ exact: true }"
            class="sidebar-link-container"
            [ngClass]="{ activeLinkChild: isActiveLink(menu.link) }"
          >
            <span class="link-icon sa-link-icon" [innerHTML]="menu.icon"></span>
            <span class="menu-title">
              {{ menu.title }}
            </span>
            <span class="svg-container" *ngIf="!isActiveLink(menu.link)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.65967 12.3536C5.44678 12.1583 5.44678 11.8417 5.65967 11.6464L9.25 8.35355C9.4629 8.15829 9.4629 7.84171 9.25 7.64645L5.65968 4.35355C5.44678 4.15829 5.44678 3.84171 5.65968 3.64645C5.87257 3.45118 6.21775 3.45118 6.43065 3.64645L10.021 6.93934C10.6597 7.52513 10.6597 8.47487 10.021 9.06066L6.43065 12.3536C6.21775 12.5488 5.87257 12.5488 5.65967 12.3536Z"
                  fill="#1C1C1C"
                />
              </svg>
            </span>
          </a>
        </div>
        <div *ngIf="menu.hasGroup" (click)="showSubmenu(itemEl)" class="pt-0 pb-0 cursor-pointer">
          <a [routerLinkActive]="['activeLink']" [routerLinkActiveOptions]="{ exact: true }">
            <span class="link-icon">
              <img [src]="menu.icon" [alt]="menu.title" />
            </span>
            <span class="">
              {{ menu.title }}
            </span>
            <div class="sidebar-anchor-tab-flex-text-container ms-auto link-icon">
              <img
                class="sidebar-anchor-tab-flex-img arrow"
                [src]="
                  '/assets/images/dashboard/icon/arrow-right' +
                  (!isDarkTheme ? '.svg' : '-dark.svg')
                "
                alt="icon"
              />
            </div>
          </a>
        </div>
        <ul class="sub-menu"></ul>
      </div>
    </div>

    <div class="d-flex align-items-center meta-footer">
      <span>Powered By &nbsp;<img src="assets/images/navbar/gb-small.png" alt="gb-small" /> </span>
    </div>
  </div>
</div>
