import { CommonModule } from '@angular/common';
import { ComponentFactoryResolver, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { NgxPaginationModule } from 'ngx-pagination';
import { DialogComponent } from './component/dialog/dialog.component';
import { DialogService } from './component/dialog/dialog.service';
import { LoadingSpinnerComponent } from './component/loading-spinner/loading-spinner.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { PaymentStatusComponent } from './component/payment-status/payment-status.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { TransactionStatusComponent } from './component/transaction-status/transaction-status.component';
import { CurrencySymbolPipe } from './custom-pipe/currency-symbol.pipe';
import { SearchFilterPipe } from './custom-pipe/search-filter.pipe';
import { AlertComponent } from './dialog/alert/alert.component';
import { OrderStatusComponent } from './dialog/order-status/order-status.component';
import { PaymentQrComponent } from './dialog/payment-qr/payment-qr.component';
import { PurchaseFlowComponent } from './dialog/purchase-flow/purchase-flow.component';
import { SupportRequestInfoComponent } from './dialog/support-request-info/support-request-info.component';
import { EllipsisDirective } from './directive/ellipsis.directive';
import { InputValidatorDirective } from './directive/input-validator.directive';
import { StatusColorDirective } from './directive/status-color.directive';
import { TooltipDirective } from './directive/tooltip.directive';
import { TrimWhitespaceDirective } from './directive/trim-whitespace.directive';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function playerFactory(): any {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    NotFoundComponent,
    SearchFilterPipe,
    TooltipDirective,
    DialogComponent,
    AlertComponent,
    LoadingSpinnerComponent,
    PaymentStatusComponent,
    StatusColorDirective,
    SupportRequestInfoComponent,
    CurrencySymbolPipe,
    TransactionStatusComponent,
    EllipsisDirective,
    PurchaseFlowComponent,
    PaymentQrComponent,
    OrderStatusComponent,
    TrimWhitespaceDirective,
    InputValidatorDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    NgSelectModule,
    QRCodeModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
  ],
  exports: [
    SidebarComponent,
    TooltipDirective,
    EllipsisDirective,
    NavbarComponent,
    SearchFilterPipe,
    NgxPaginationModule,
    DialogComponent,
    LoadingSpinnerComponent,
    StatusColorDirective,
    NgSelectModule,
    CurrencySymbolPipe,
    QRCodeModule,
    LottieCacheModule,
    LottieModule,
    TrimWhitespaceDirective,
    InputValidatorDirective,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DialogService],
    };
  }
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public resolveComponent(component: any) {
    return this.componentFactoryResolver.resolveComponentFactory(component);
  }
}
