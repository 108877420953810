<div class="row thanks-modal-outer-div" *ngIf="!inProgress; else spinner">
  <div class="col-12">
    <div
      class="svg-container"
      *ngIf="
        (transactionId && transactionId !== '') ||
          (orderId && orderId !== '') ||
          success !== undefined;
        else errorSvg
      "
    >
      <svg
        class="success-svg"
        width="132"
        height="132"
        viewBox="0 0 132 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle class="circle-svg-primary" cx="66" cy="66" r="66" fill="#6365EF" fill-opacity="0.1" />
        <circle cx="65.999" cy="65.999" r="48.7451" fill="url(#paint0_linear_156_9122)" />
        <path
          d="M60.108 71.491L78.772 52.827C79.352 52.247 80.0901 51.957 80.9864 51.957C81.8827 51.957 82.6208 52.247 83.2008 52.827C83.7807 53.4069 84.0707 54.145 84.0707 55.0413C84.0707 55.9376 83.7807 56.6758 83.2008 57.2557L62.3224 78.1342C61.6897 78.7668 60.9515 79.0832 60.108 79.0832C59.2644 79.0832 58.5263 78.7668 57.8936 78.1342L49.6688 69.9093C49.0888 69.3294 48.7988 68.5912 48.7988 67.6949C48.7988 66.7986 49.0888 66.0605 49.6688 65.4806C50.2487 64.9006 50.9868 64.6106 51.8831 64.6106C52.7794 64.6106 53.5176 64.9006 54.0975 65.4806L60.108 71.491Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_156_9122"
            x1="92.3127"
            y1="17.2539"
            x2="50.0382"
            y2="109.999"
            gradientUnits="userSpaceOnUse"
          >
            <stop class="stop-svg-primary" stop-color="#6365EF" />
            <stop class="stop-svg-primary" offset="1" stop-color="#6365EF" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <ng-template #errorSvg>
      <div class="modal-common-circle outer-circle failed-icon">
        <div class="modal-common-circle inner-circle">
          <svg
            width="132"
            height="132"
            viewBox="0 0 132 132"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="66" cy="66" r="66" fill="#D92D20" fill-opacity="0.1" />
            <circle cx="65.999" cy="65.999" r="48.7451" fill="url(#paint0_linear_6395_8391)" />
            <mask
              id="mask0_6395_8391"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="41"
              y="41"
              width="50"
              height="50"
            >
              <rect x="41" y="41" width="50" height="50" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6395_8391)">
              <path
                d="M65.9989 76.7132C66.606 76.7132 67.115 76.5078 67.5257 76.0971C67.9364 75.6864 68.1417 75.1775 68.1417 74.5703C68.1417 73.9632 67.9364 73.4542 67.5257 73.0435C67.115 72.6328 66.606 72.4275 65.9989 72.4275C65.3917 72.4275 64.8828 72.6328 64.4721 73.0435C64.0614 73.4542 63.856 73.9632 63.856 74.5703C63.856 75.1775 64.0614 75.6864 64.4721 76.0971C64.8828 76.5078 65.3917 76.7132 65.9989 76.7132ZM63.856 68.1417H68.1417V55.2846H63.856V68.1417ZM65.9989 87.4275C63.0346 87.4275 60.2489 86.865 57.6417 85.74C55.0346 84.615 52.7667 83.0882 50.8382 81.1596C48.9096 79.231 47.3828 76.9632 46.2578 74.356C45.1328 71.7489 44.5703 68.9632 44.5703 65.9989C44.5703 63.0346 45.1328 60.2489 46.2578 57.6417C47.3828 55.0346 48.9096 52.7667 50.8382 50.8382C52.7667 48.9096 55.0346 47.3828 57.6417 46.2578C60.2489 45.1328 63.0346 44.5703 65.9989 44.5703C68.9632 44.5703 71.7489 45.1328 74.356 46.2578C76.9632 47.3828 79.231 48.9096 81.1596 50.8382C83.0882 52.7667 84.615 55.0346 85.74 57.6417C86.865 60.2489 87.4275 63.0346 87.4275 65.9989C87.4275 68.9632 86.865 71.7489 85.74 74.356C84.615 76.9632 83.0882 79.231 81.1596 81.1596C79.231 83.0882 76.9632 84.615 74.356 85.74C71.7489 86.865 68.9632 87.4275 65.9989 87.4275ZM65.9989 83.1417C70.7846 83.1417 74.8382 81.481 78.1596 78.1596C81.481 74.8382 83.1417 70.7846 83.1417 65.9989C83.1417 61.2132 81.481 57.1596 78.1596 53.8382C74.8382 50.5167 70.7846 48.856 65.9989 48.856C61.2132 48.856 57.1596 50.5167 53.8382 53.8382C50.5167 57.1596 48.856 61.2132 48.856 65.9989C48.856 70.7846 50.5167 74.8382 53.8382 78.1596C57.1596 81.481 61.2132 83.1417 65.9989 83.1417Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_6395_8391"
                x1="92.3127"
                y1="17.2539"
                x2="50.0382"
                y2="109.999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#D92D20" />
                <stop offset="1" stop-color="#D92D20" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </ng-template>

    <div class="card shadow-card"></div>
    <div class="card">
      <div class="card-body px-0">
        <div
          class="row"
          *ngIf="(!transactionId || transactionId === '') && (!orderId || orderId === '')"
        >
          <div class="offset-10 col-2">
            <svg
              (click)="close()"
              class="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15 5L5 15M5 5L15 15"
                stroke="black"
                stroke-opacity="0.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div
          class="row"
          [ngClass]="{
            'mt-5':
              (transactionId && transactionId !== '') ||
              (orderId && orderId !== '') ||
              success !== undefined
          }"
        >
          <div class="col-12 m-auto">
            <div class="thanks-modal-inner-div">
              <h4 class="text-center mb-3 heading">
                {{
                  (transactionId && transactionId !== '') ||
                  (orderId && orderId !== '') ||
                  success !== undefined
                    ? 'Payment Success!'
                    : 'Payment Failed!'
                }}
              </h4>

              <ng-container
                *ngIf="
                  (transactionId && transactionId !== '') ||
                    (orderId && orderId !== '') ||
                    success !== undefined;
                  else errMsg
                "
              >
                <p class="text-center fw-normal m-0 sub-heading">Your purchase was successful.</p>

                <br />

                <p class="text-center fw-normal m-0 sub-heading-2">
                  We have successfully sent an installation QR code to each individual travellers on
                  their email ID’s.
                </p>

                <p class="text-center description mb-1">
                  Note: QR for all the travellers has been sent to you on your registered email
                  address.
                </p>
              </ng-container>

              <ng-template #errMsg>
                <p class="text-center fw-normal m-0 sub-heading mb-3">Your purchase was failed.</p>
                <br />
              </ng-template>
            </div>

            <div class="col-11 m-auto mb-2">
              <div class="d-flex align-items-center justify-content-center">
                <button
                  type="submit"
                  class="btn btn-setting mb-1 rounded-pill w-100"
                  (click)="close()"
                >
                  <p class="mb-0 flex-fill text-white text-center">
                    {{
                      (transactionId && transactionId !== '') ||
                      (orderId && orderId !== '') ||
                      success !== undefined
                        ? 'Home'
                        : 'Retry Payment'
                    }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loading Spinner - Start -->
<ng-template #spinner class="loader-align">
  <app-loading-spinner></app-loading-spinner>
</ng-template>
<!-- Loading Spinner - End -->
