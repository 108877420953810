import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimWhitespace]',
})
export class TrimWhitespaceDirective {
  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.replace(/\s/g, ''); // Remove spaces

    if (inputElement.value !== sanitizedValue) {
      inputElement.value = sanitizedValue; // Update input value without spaces
      this.control.control?.setValue(sanitizedValue); // Update form control value
    }
  }
}
