<div class="servicing-img position-relative walkthrough-video-section">
  <div class="walkthrough-video-container" id="videoContainer">
    <video
      class="walkthrough-video"
      id="video"
      controls
      controlslist="nodownload"
      autoplay
      poster="/assets/images/common/walkthrough-poster.png"
    >
      <source src="https://assets.glowingbud.com/trs/trs-walkthrough.mp4" type="video/mp4" />
    </video>

    <button class="close-btn" (click)="close()">
      <svg
        class="cursor-pointer w-100 h-100"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="black"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</div>
